// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as ApolloClient from "reason-apollo-client/src/ApolloClient.bs.js";
import * as ApolloClient__ApolloClient from "reason-apollo-client/src/@apollo/client/ApolloClient__ApolloClient.bs.js";
import * as ApolloClient__Link_Http_HttpLink from "reason-apollo-client/src/@apollo/client/link/http/ApolloClient__Link_Http_HttpLink.bs.js";
import * as ApolloClient__Cache_InMemory_InMemoryCache from "reason-apollo-client/src/@apollo/client/cache/inmemory/ApolloClient__Cache_InMemory_InMemoryCache.bs.js";

var graphqlEndpoint = "api.wildcards.world/v1/graphql";

var optAdminSecret = localStorage.getItem("hasura-admin-secret");

var headers = optAdminSecret !== null ? ({
      "x-hasura-admin-secret": optAdminSecret
    }) : ({
      "x-hasura-admin-secret": "n[I>OWsXsUw9hG8^pGBU"
    });

var httpLink = ApolloClient__Link_Http_HttpLink.make((function (param) {
        return "https://api.wildcards.world/v1/graphql";
      }), undefined, undefined, Caml_option.some(headers), undefined, undefined, undefined, undefined);

var instance = ApolloClient.make(undefined, undefined, undefined, Caml_option.some(httpLink), ApolloClient__Cache_InMemory_InMemoryCache.make(undefined, undefined, undefined, undefined, undefined, undefined), undefined, undefined, true, undefined, ApolloClient__ApolloClient.DefaultOptions.make(ApolloClient__ApolloClient.DefaultMutateOptions.make(undefined, undefined, true, /* All */2, undefined, undefined), ApolloClient__ApolloClient.DefaultQueryOptions.make(/* NetworkOnly */2, /* All */2, undefined, undefined), ApolloClient__ApolloClient.DefaultWatchQueryOptions.make(/* NetworkOnly */3, /* All */2, undefined, undefined), undefined), undefined, undefined, undefined, undefined, undefined, undefined, undefined);

export {
  graphqlEndpoint ,
  headers ,
  httpLink ,
  instance ,
  
}
/* optAdminSecret Not a pure module */

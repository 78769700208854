// Generated by ReScript, PLEASE EDIT WITH CARE

import * as GqlConverters from "../GqlConverters.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "reason-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  query AllCards  {
    wildcardData  {
      __typename
      commonName
      artistOfWildcard  {
        __typename
        name
        id
        eth_address
        website
      }
      nftId: id
      description
      image
      key
      name
      species
      real_wc_photos  {
        __typename
        photographer
        image
      }
      organization  {
        __typename
        id
        name
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.wildcardData;
  return {
          wildcardData: value$1.map(function (value) {
                var value$1 = value.commonName;
                var value$2 = value.artistOfWildcard;
                var tmp;
                if (value$2 == null) {
                  tmp = undefined;
                } else {
                  var value$3 = value$2.eth_address;
                  var value$4 = value$2.website;
                  tmp = {
                    __typename: value$2.__typename,
                    name: value$2.name,
                    id: value$2.id,
                    eth_address: !(value$3 == null) ? value$3 : undefined,
                    website: !(value$4 == null) ? value$4 : undefined
                  };
                }
                var value$5 = value.nftId;
                var value$6 = value.image;
                var value$7 = value.name;
                var value$8 = value.species;
                var value$9 = value.real_wc_photos;
                var value$10 = value.organization;
                return {
                        __typename: value.__typename,
                        commonName: !(value$1 == null) ? value$1 : undefined,
                        artistOfWildcard: tmp,
                        nftId: !(value$5 == null) ? value$5 : undefined,
                        description: GqlConverters.StringArray.parse(value.description),
                        image: !(value$6 == null) ? value$6 : undefined,
                        key: value.key,
                        name: !(value$7 == null) ? value$7 : undefined,
                        species: !(value$8 == null) ? value$8 : undefined,
                        real_wc_photos: value$9.map(function (value) {
                              var value$1 = value.photographer;
                              return {
                                      __typename: value.__typename,
                                      photographer: !(value$1 == null) ? value$1 : undefined,
                                      image: value.image
                                    };
                            }),
                        organization: !(value$10 == null) ? ({
                              __typename: value$10.__typename,
                              id: value$10.id,
                              name: value$10.name
                            }) : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.wildcardData;
  var wildcardData = value$1.map(function (value) {
        var value$1 = value.organization;
        var organization;
        if (value$1 !== undefined) {
          var value$2 = value$1.name;
          var value$3 = value$1.id;
          var value$4 = value$1.__typename;
          organization = {
            __typename: value$4,
            id: value$3,
            name: value$2
          };
        } else {
          organization = null;
        }
        var value$5 = value.real_wc_photos;
        var real_wc_photos = value$5.map(function (value) {
              var value$1 = value.image;
              var value$2 = value.photographer;
              var photographer = value$2 !== undefined ? value$2 : null;
              var value$3 = value.__typename;
              return {
                      __typename: value$3,
                      photographer: photographer,
                      image: value$1
                    };
            });
        var value$6 = value.species;
        var species = value$6 !== undefined ? value$6 : null;
        var value$7 = value.name;
        var name = value$7 !== undefined ? value$7 : null;
        var value$8 = value.key;
        var value$9 = value.image;
        var image = value$9 !== undefined ? value$9 : null;
        var value$10 = value.description;
        var value$11 = GqlConverters.StringArray.serialize(value$10);
        var value$12 = value.nftId;
        var nftId = value$12 !== undefined ? value$12 : null;
        var value$13 = value.artistOfWildcard;
        var artistOfWildcard;
        if (value$13 !== undefined) {
          var value$14 = value$13.website;
          var website = value$14 !== undefined ? value$14 : null;
          var value$15 = value$13.eth_address;
          var eth_address = value$15 !== undefined ? value$15 : null;
          var value$16 = value$13.id;
          var value$17 = value$13.name;
          var value$18 = value$13.__typename;
          artistOfWildcard = {
            __typename: value$18,
            name: value$17,
            id: value$16,
            eth_address: eth_address,
            website: website
          };
        } else {
          artistOfWildcard = null;
        }
        var value$19 = value.commonName;
        var commonName = value$19 !== undefined ? value$19 : null;
        var value$20 = value.__typename;
        return {
                __typename: value$20,
                commonName: commonName,
                artistOfWildcard: artistOfWildcard,
                nftId: nftId,
                description: value$11,
                image: image,
                key: value$8,
                name: name,
                species: species,
                real_wc_photos: real_wc_photos,
                organization: organization
              };
      });
  return {
          wildcardData: wildcardData
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var AllWildcardsData_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var AllWildcardsData_refetchQueryDescription = include.refetchQueryDescription;

var AllWildcardsData_use = include.use;

var AllWildcardsData_useLazy = include.useLazy;

var AllWildcardsData_useLazyWithVariables = include.useLazyWithVariables;

var AllWildcardsData = {
  AllWildcardsData_inner: AllWildcardsData_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: AllWildcardsData_refetchQueryDescription,
  use: AllWildcardsData_use,
  useLazy: AllWildcardsData_useLazy,
  useLazyWithVariables: AllWildcardsData_useLazyWithVariables
};

export {
  AllWildcardsData ,
  
}
/* query Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Router from "./Router.bs.js";
import * as Styles from "./Styles.bs.js";
import * as OrgOverview from "./OrgOverview.bs.js";
import * as CardOverview from "./CardOverview.bs.js";

function AdminTopLevel(Props) {
  var route = Router.useRouter(undefined);
  return React.createElement("div", undefined, React.createElement("button", {
                  className: Styles.buttonStyles,
                  onClick: (function (param) {
                      return Router.onRouteClick(/* Cards */1, param);
                    })
                }, "Cards"), React.createElement("button", {
                  className: Styles.buttonStyles,
                  onClick: (function (param) {
                      return Router.onRouteClick(/* Orgs */0, param);
                    })
                }, "Orgs"), route ? React.createElement(CardOverview.make, {}) : React.createElement(OrgOverview.make, {}));
}

var make = AdminTopLevel;

export {
  make ,
  
}
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ReasonReactRouter from "reason-react/src/ReasonReactRouter.bs.js";

function fromUrl(url) {
  var match = url.path;
  if (!match) {
    return /* Cards */1;
  }
  switch (match.hd) {
    case "cards" :
        return /* Cards */1;
    case "orgs" :
        var match$1 = match.tl;
        if (match$1 && !(match$1.hd === "" && !match$1.tl)) {
          return /* Cards */1;
        } else {
          return /* Orgs */0;
        }
    default:
      return /* Cards */1;
  }
}

function toString(route) {
  if (route) {
    return "/cards";
  } else {
    return "/orgs";
  }
}

var Route = {
  fromUrl: fromUrl,
  toString: toString
};

function useRouter(param) {
  return fromUrl(ReasonReactRouter.useUrl(undefined, undefined));
}

function onRouteClick(page, $$event) {
  if (!$$event.defaultPrevented && $$event.button === 0 && !$$event.altKey && !$$event.ctrlKey && !$$event.metaKey && !$$event.shiftKey) {
    $$event.preventDefault();
    return ReasonReactRouter.push(page ? "/cards" : "/orgs");
  }
  
}

export {
  Route ,
  useRouter ,
  onRouteClick ,
  
}
/* ReasonReactRouter Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE


var customStyles = {
  content: {
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%"
  }
};

export {
  customStyles ,
  
}
/* No side effect */

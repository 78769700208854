// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Client from "./gql-client/Client.bs.js";
import * as AdminTopLevel from "./AdminTopLevel.bs.js";
import * as Client$1 from "@apollo/client";

function App(Props) {
  return React.createElement(Client$1.ApolloProvider, {
              client: Client.instance,
              children: React.createElement(AdminTopLevel.make, {})
            });
}

var make = App;

export {
  make ,
  
}
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";

function AuthComponent(Props) {
  var children = Props.children;
  var match = React.useState(function () {
        return Belt_Option.isSome(Caml_option.null_to_opt(localStorage.getItem("hasura-admin-secret")));
      });
  var setAuthSet = match[1];
  var match$1 = React.useState(function () {
        return "";
      });
  var setAuthHeader = match$1[1];
  var authHeader = match$1[0];
  var onChange = function (e) {
    return Curry._1(setAuthHeader, e.target.value);
  };
  if (match[0]) {
    return React.createElement(React.Fragment, undefined, React.createElement("button", {
                    onClick: (function (param) {
                        return Curry._1(setAuthSet, (function (param) {
                                      return false;
                                    }));
                      })
                  }, "Edit your auth key"), React.createElement("br", undefined), children);
  } else {
    return React.createElement("form", {
                onSubmit: (function ($$event) {
                    localStorage.setItem("hasura-admin-secret", authHeader);
                    $$event.preventDefault();
                    return Curry._1(setAuthSet, (function (param) {
                                  return true;
                                }));
                  })
              }, React.createElement("label", undefined, "Auth Key: "), React.createElement("input", {
                    name: "auth_key",
                    type: "text",
                    onChange: onChange
                  }), React.createElement("button", {
                    type: "submit"
                  }, "submit"), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("p", undefined, "NOTE: you may need to reload the webpage after doing this to activate the key"));
  }
}

var make = AuthComponent;

export {
  make ,
  
}
/* react Not a pure module */

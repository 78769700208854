// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Decco from "decco/src/Decco.bs.js";
import * as React from "react";
import * as Queries from "./lib/Queries.bs.js";
import * as Youtube from "./components/Youtube.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ReactModal from "./components/ReactModal.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Description from "./components/Description.bs.js";
import ReactModal$1 from "react-modal";
import * as AddWildcards from "./AddWildcards.bs.js";
import * as CDNImageLink from "./components/CDNImageLink.bs.js";
import * as AuthComponent from "./components/AuthComponent.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "reason-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

((require("./styles/css/create-stream.css")));

((require("./styles/css/global.css")));

((require("./styles/css/defaults.css")));

function animalDescription_decode(v) {
  return Decco.arrayFromJson(Decco.stringFromJson, v);
}

var $pipe$pipe$pipe$pipe = Belt_Option.getWithDefault;

function parse(json) {
  return Belt_Result.getWithDefault(Decco.arrayFromJson(Decco.stringFromJson, json), []);
}

function serialize(array) {
  return array;
}

var StringArray = {
  parse: parse,
  serialize: serialize
};

var Raw = {};

var query = (require("@apollo/client").gql`
  mutation ($inp: [real_wc_photos_insert_input!]!)  {
    insert_real_wc_photos(objects: $inp)  {
      __typename
      affected_rows
    }
  }
`);

function parse$1(value) {
  var value$1 = value.insert_real_wc_photos;
  return {
          insert_real_wc_photos: !(value$1 == null) ? ({
                __typename: value$1.__typename,
                affected_rows: value$1.affected_rows
              }) : undefined
        };
}

function serialize$1(value) {
  var value$1 = value.insert_real_wc_photos;
  var insert_real_wc_photos;
  if (value$1 !== undefined) {
    var value$2 = value$1.affected_rows;
    var value$3 = value$1.__typename;
    insert_real_wc_photos = {
      __typename: value$3,
      affected_rows: value$2
    };
  } else {
    insert_real_wc_photos = null;
  }
  return {
          insert_real_wc_photos: insert_real_wc_photos
        };
}

function serializeInputObjectreal_wc_photos_insert_input(inp) {
  var a = inp.image;
  var a$1 = inp.photographer;
  var a$2 = inp.wildcardKey;
  return {
          image: a !== undefined ? a : undefined,
          photographer: a$1 !== undefined ? a$1 : undefined,
          wildcardKey: a$2 !== undefined ? a$2 : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.inp;
  return {
          inp: a.map(serializeInputObjectreal_wc_photos_insert_input)
        };
}

function makeVariables(inp, param) {
  return {
          inp: inp
        };
}

function makeInputObjectreal_wc_photos_insert_input(image, photographer, wildcardKey, param) {
  return {
          image: image,
          photographer: photographer,
          wildcardKey: wildcardKey
        };
}

var AddRealWildcardImage_inner = {
  Raw: Raw,
  query: query,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  serializeInputObjectreal_wc_photos_insert_input: serializeInputObjectreal_wc_photos_insert_input,
  makeVariables: makeVariables,
  makeInputObjectreal_wc_photos_insert_input: makeInputObjectreal_wc_photos_insert_input
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AddRealWildcardImage_useWithVariables = include.useWithVariables;

var AddRealWildcardImage = {
  AddRealWildcardImage_inner: AddRealWildcardImage_inner,
  Raw: Raw,
  query: query,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables,
  serializeInputObjectreal_wc_photos_insert_input: serializeInputObjectreal_wc_photos_insert_input,
  makeVariables: makeVariables,
  makeInputObjectreal_wc_photos_insert_input: makeInputObjectreal_wc_photos_insert_input,
  use: use,
  useWithVariables: AddRealWildcardImage_useWithVariables
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  mutation ($key: Int!, $image: String!)  {
    update_wildcardData_by_pk(pk_columns: {key: $key}, _set: {image: $image})  {
      __typename
      image
    }
  }
`);

function parse$2(value) {
  var value$1 = value.update_wildcardData_by_pk;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.image;
    tmp = {
      __typename: value$1.__typename,
      image: !(value$2 == null) ? value$2 : undefined
    };
  }
  return {
          update_wildcardData_by_pk: tmp
        };
}

function serialize$2(value) {
  var value$1 = value.update_wildcardData_by_pk;
  var update_wildcardData_by_pk;
  if (value$1 !== undefined) {
    var value$2 = value$1.image;
    var image = value$2 !== undefined ? value$2 : null;
    var value$3 = value$1.__typename;
    update_wildcardData_by_pk = {
      __typename: value$3,
      image: image
    };
  } else {
    update_wildcardData_by_pk = null;
  }
  return {
          update_wildcardData_by_pk: update_wildcardData_by_pk
        };
}

function serializeVariables$1(inp) {
  return {
          key: inp.key,
          image: inp.image
        };
}

function makeVariables$1(key, image, param) {
  return {
          key: key,
          image: image
        };
}

var AddWildcardAnimation_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1
};

var include$1 = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$2,
      serialize: serialize$2,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var AddWildcardAnimation_useWithVariables = include$1.useWithVariables;

var AddWildcardAnimation = {
  AddWildcardAnimation_inner: AddWildcardAnimation_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$2,
  serialize: serialize$2,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  use: use$1,
  useWithVariables: AddWildcardAnimation_useWithVariables
};

function CardOverview$AddRealImages(Props) {
  var imageKey = Props.imageKey;
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return /* NotSet */1;
      });
  var setImageStatus = match$1[1];
  var imageStatus = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setPhotographer = match$2[1];
  var photographer = match$2[0];
  var match$3 = React.useState(function () {
        return "";
      });
  var setLink = match$3[1];
  var link = match$3[0];
  var onChange = function (e) {
    return Curry._1(setLink, e.target.value);
  };
  var onChangePhotographer = function (e) {
    var value = e.target.value;
    return Curry._1(setPhotographer, (function (param) {
                  return value;
                }));
  };
  var setImageForm = React.createElement("form", {
        onSubmit: (function ($$event) {
            $$event.preventDefault();
            return Curry._1(setImageStatus, (function (param) {
                          return {
                                  TAG: /* Set */0,
                                  _0: link
                                };
                        }));
          })
      }, React.createElement("label", undefined, "Image link (remember, it starts with \"/\"): "), React.createElement("br", undefined), React.createElement("input", {
            name: "auth_key",
            type: "text",
            onChange: onChange
          }), React.createElement("br", undefined), React.createElement("label", undefined, "Has a photographer?"), React.createElement("input", {
            name: "has_hotographer",
            type: "checkbox",
            onChange: (function (e) {
                var value = e.target.checked;
                console.log("The event", e);
                console.log("The value", value);
                Curry._1(setPhotographer, (function (param) {
                        if (value) {
                          return "";
                        }
                        
                      }));
                
              })
          }), photographer !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("br", undefined), React.createElement("label", undefined, "Photographer name: "), React.createElement("input", {
                  name: "auth_key",
                  type: "text",
                  onChange: onChangePhotographer
                })) : null, React.createElement("br", undefined), React.createElement("button", undefined, "Submit(test image is correct)"));
  var tmp;
  if (typeof imageStatus === "number") {
    tmp = imageStatus === /* Done */0 ? null : setImageForm;
  } else {
    switch (imageStatus.TAG | 0) {
      case /* Set */0 :
          var imageLink = imageStatus._0;
          tmp = React.createElement(React.Fragment, undefined, React.createElement("a", {
                    href: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink,
                    target: "_blank"
                  }, "Link to image (use it to debug issues...)"), React.createElement("img", {
                    src: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink,
                    onError: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* ErrorLoading */2,
                                              _0: imageLink
                                            };
                                    }));
                      }),
                    onLoad: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* Loaded */1,
                                              _0: imageLink
                                            };
                                    }));
                      })
                  }));
          break;
      case /* Loaded */1 :
          var imageLink$1 = imageStatus._0;
          tmp = React.createElement(React.Fragment, undefined, React.createElement("button", {
                    onClick: (function (param) {
                        Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(Queries.AllWildcardsData.refetchQueryDescription, undefined, undefined, undefined)], undefined, {
                              inp: [{
                                  image: imageLink$1,
                                  photographer: photographer,
                                  wildcardKey: imageKey
                                }]
                            });
                        return Curry._1(setImageStatus, (function (param) {
                                      return /* Done */0;
                                    }));
                      })
                  }, "Set the image"), React.createElement("img", {
                    src: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink$1,
                    onError: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* ErrorLoading */2,
                                              _0: imageLink$1
                                            };
                                    }));
                      }),
                    onLoad: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* Loaded */1,
                                              _0: imageLink$1
                                            };
                                    }));
                      })
                  }));
          break;
      case /* ErrorLoading */2 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "There was an error loading that image, please check the link is correct:"), React.createElement("a", {
                    href: "https://dd2wadt5nc0o7.cloudfront.net" + imageStatus._0,
                    target: "_blank"
                  }, "Link to image (use it to debug issues...)"), React.createElement("br", undefined), React.createElement("h3", undefined, "try again"), setImageForm);
          break;
      
    }
  }
  var tmp$1;
  var exit = 0;
  if (result.called) {
    if (result.loading) {
      tmp$1 = "Loading...";
    } else if (result.data !== undefined && result.error === undefined) {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "New image added!"), React.createElement("br", undefined), typeof imageStatus === "number" && imageStatus === 0 ? React.createElement("button", {
                  onClick: (function (param) {
                      return Curry._1(setImageStatus, (function (param) {
                                    return /* NotSet */1;
                                  }));
                    })
                }, "Add another image?") : null);
    } else {
      exit = 1;
    }
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    var error = result.error;
    tmp$1 = React.createElement(React.Fragment, undefined, "Error loading data", error !== undefined ? ": " + error.message : null);
  }
  return React.createElement("div", undefined, React.createElement("h3", undefined, "Add new image:"), React.createElement(AuthComponent.make, {
                  children: null
                }, React.createElement(React.Fragment, undefined, tmp), tmp$1));
}

var AddRealImages = {
  make: CardOverview$AddRealImages
};

function CardOverview$AddAnimation(Props) {
  var imageKey = Props.imageKey;
  var match = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  var match$1 = React.useState(function () {
        return /* NotSet */1;
      });
  var setImageStatus = match$1[1];
  var imageStatus = match$1[0];
  var match$2 = React.useState(function () {
        
      });
  var setArtist = match$2[1];
  var match$3 = React.useState(function () {
        return "";
      });
  var setLink = match$3[1];
  var link = match$3[0];
  var onChange = function (e) {
    return Curry._1(setLink, e.target.value);
  };
  var onChangeArtist = function (e) {
    var value = e.target.value;
    return Curry._1(setArtist, (function (param) {
                  return Caml_option.some(value);
                }));
  };
  var setImageForm = React.createElement("form", {
        onSubmit: (function ($$event) {
            $$event.preventDefault();
            return Curry._1(setImageStatus, (function (param) {
                          return {
                                  TAG: /* Set */0,
                                  _0: link
                                };
                        }));
          })
      }, React.createElement("label", undefined, "Image link (remember, it starts with \"/\"): "), React.createElement("br", undefined), React.createElement("input", {
            name: "auth_key",
            type: "text",
            onChange: onChange
          }), React.createElement("br", undefined), React.createElement(React.Fragment, undefined, React.createElement("br", undefined), React.createElement("label", undefined, "TODO: select the artist of this avatar"), React.createElement("input", {
                disabled: true,
                name: "auth_key",
                type: "text",
                onChange: onChangeArtist
              })), React.createElement("br", undefined), React.createElement("button", undefined, "Submit (test animation is correct)"));
  var tmp;
  if (typeof imageStatus === "number") {
    tmp = imageStatus === /* Done */0 ? null : setImageForm;
  } else {
    switch (imageStatus.TAG | 0) {
      case /* Set */0 :
          var imageLink = imageStatus._0;
          tmp = React.createElement(React.Fragment, undefined, React.createElement("a", {
                    href: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink,
                    target: "_blank"
                  }, "Link to image (use it to debug issues...)"), React.createElement("img", {
                    src: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink,
                    onError: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* ErrorLoading */2,
                                              _0: imageLink
                                            };
                                    }));
                      }),
                    onLoad: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* Loaded */1,
                                              _0: imageLink
                                            };
                                    }));
                      })
                  }));
          break;
      case /* Loaded */1 :
          var imageLink$1 = imageStatus._0;
          tmp = React.createElement(React.Fragment, undefined, React.createElement("button", {
                    onClick: (function (param) {
                        Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(Queries.AllWildcardsData.refetchQueryDescription, undefined, undefined, undefined)], undefined, {
                              key: imageKey,
                              image: imageLink$1
                            });
                        return Curry._1(setImageStatus, (function (param) {
                                      return /* Done */0;
                                    }));
                      })
                  }, "Set the image"), React.createElement("img", {
                    src: "https://dd2wadt5nc0o7.cloudfront.net" + imageLink$1,
                    onError: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* ErrorLoading */2,
                                              _0: imageLink$1
                                            };
                                    }));
                      }),
                    onLoad: (function (param) {
                        return Curry._1(setImageStatus, (function (param) {
                                      return {
                                              TAG: /* Loaded */1,
                                              _0: imageLink$1
                                            };
                                    }));
                      })
                  }));
          break;
      case /* ErrorLoading */2 :
          tmp = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "There was an error loading that image, please check the link is correct:"), React.createElement("a", {
                    href: "https://dd2wadt5nc0o7.cloudfront.net" + imageStatus._0,
                    target: "_blank"
                  }, "Link to image (use it to debug issues...)"), React.createElement("br", undefined), React.createElement("h3", undefined, "try again"), setImageForm);
          break;
      
    }
  }
  var tmp$1;
  var exit = 0;
  if (result.called) {
    if (result.loading) {
      tmp$1 = "Loading...";
    } else if (result.data !== undefined && result.error === undefined) {
      tmp$1 = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "New wildcard animation added!"), React.createElement("br", undefined), typeof imageStatus === "number" && imageStatus === 0 ? React.createElement("p", undefined, "the image should be updated (you may need to reload your browser - will fix in future versions)") : null);
    } else {
      exit = 1;
    }
  } else {
    tmp$1 = null;
  }
  if (exit === 1) {
    var error = result.error;
    tmp$1 = React.createElement(React.Fragment, undefined, "Error loading data", error !== undefined ? ": " + error.message : null);
  }
  return React.createElement("div", undefined, React.createElement("h3", undefined, "Add new wildcard animation:"), React.createElement(AuthComponent.make, {
                  children: null
                }, React.createElement(React.Fragment, undefined, tmp), tmp$1));
}

var AddAnimation = {
  make: CardOverview$AddAnimation
};

function CardOverview$RealImages(Props) {
  var imageArray = Props.imageArray;
  var imageKey = Props.imageKey;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  var x = imageArray.length;
  return React.createElement("div", undefined, React.createElement(ReactModal$1, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  style: ReactModal.customStyles,
                  children: React.createElement("div", undefined, React.createElement("div", {
                            className: Curry._1(Css.style, {
                                  hd: Css.backgroundColor(Css.rgb(252, 209, 156)),
                                  tl: /* [] */0
                                })
                          }, React.createElement(CardOverview$AddRealImages, {
                                imageKey: imageKey
                              })), React.createElement("hr", undefined), React.createElement("hr", undefined), React.createElement("h1", undefined, "Images:"), Belt_Array.map(imageArray, (function (param) {
                              return React.createElement(React.Fragment, undefined, React.createElement("img", {
                                              src: "https://dd2wadt5nc0o7.cloudfront.net" + param.image
                                            }), Belt_Option.mapWithDefault(param.photographer, null, (function (prim) {
                                                return prim;
                                              })));
                            })))
                }), React.createElement("div", {
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, x !== 0 ? String(x) + " real images" : "NO IMAGES"));
}

var RealImages = {
  make: CardOverview$RealImages
};

function CardOverview$YoutubeModal(Props) {
  var optVideoCode = Props.optVideoCode;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement("div", undefined, optVideoCode !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(ReactModal$1, {
                        isOpen: match[0],
                        contentLabel: "hello world",
                        onAfterOpen: (function (param) {
                            
                          }),
                        onRequestClose: (function (param) {
                            return Curry._1(setShowModal, (function (param) {
                                          return false;
                                        }));
                          }),
                        style: ReactModal.customStyles,
                        children: React.createElement(Youtube.make, {
                              videoCode: optVideoCode
                            })
                      }), React.createElement("div", {
                        onClick: (function (param) {
                            return Curry._1(setShowModal, (function (param) {
                                          return true;
                                        }));
                          })
                      }, optVideoCode)) : "NO VIDEO");
}

var YoutubeModal = {
  make: CardOverview$YoutubeModal
};

function CardOverview$AddCardsModal(Props) {
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement(React.Fragment, undefined, React.createElement(ReactModal$1, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(AddWildcards.make, {})
                }), React.createElement("button", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Css.white),
                        tl: /* [] */0
                      }),
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, "Add new wildcards"));
}

var AddCardsModal = {
  make: CardOverview$AddCardsModal
};

function CardOverview$Onboarding(Props) {
  var onboarding_status = Props.onboarding_status;
  return React.createElement("div", undefined, "", onboarding_status === "live" ? "Live" : (
                onboarding_status === "declined" ? "Declined" : (
                    onboarding_status === "unprocessed" ? "Unprocessed" : (
                        onboarding_status === "interested" ? "Interested" : (
                            onboarding_status === "listed" ? "Listed" : (
                                onboarding_status === "rejected" ? "Rejected" : (
                                    onboarding_status === "signed" ? "Signed " : (
                                        onboarding_status === "contacted" ? "Contacted" : "Unknown - please update ui"
                                      )
                                  )
                              )
                          )
                      )
                  )
              ));
}

var Onboarding = {
  make: CardOverview$Onboarding
};

function CardOverview(Props) {
  var allOrgsQuery = Curry.app(Queries.AllWildcardsData.use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = React.useState(function () {
        return false;
      });
  var setShowFilters = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowActions = match$1[1];
  var onClick = function (id, _event) {
    console.log("clicked the id: " + id);
    
  };
  var match$2 = allOrgsQuery.data;
  var error = allOrgsQuery.error;
  return React.createElement(React.Fragment, undefined, React.createElement("br", undefined), match[0] ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                        onClick: (function (param) {
                            return Curry._1(setShowFilters, (function (param) {
                                          return false;
                                        }));
                          })
                      }, "Close Filters"), React.createElement("h2", undefined, "TODO: add filters. - be patient, Jason will do it one day")) : React.createElement(React.Fragment, undefined, React.createElement("a", {
                        onClick: (function (param) {
                            return Curry._1(setShowFilters, (function (param) {
                                          return true;
                                        }));
                          })
                      }, "Show Filters")), React.createElement("br", undefined), match$1[0] ? React.createElement(React.Fragment, undefined, React.createElement("a", {
                        onClick: (function (param) {
                            return Curry._1(setShowActions, (function (param) {
                                          return false;
                                        }));
                          })
                      }, "Close Actions"), React.createElement("br", undefined), React.createElement(CardOverview$AddCardsModal, {})) : React.createElement(React.Fragment, undefined, React.createElement("a", {
                        onClick: (function (param) {
                            return Curry._1(setShowActions, (function (param) {
                                          return true;
                                        }));
                          })
                      }, "Show Actions")), React.createElement("br", undefined), allOrgsQuery.loading ? "Loading..." : (
                error !== undefined ? React.createElement(AuthComponent.make, {
                        children: "Error loading data - message: " + (error.message + "\n\n(copy paste the message to Jason on slack, but try reload the page first)")
                      }) : (
                    match$2 !== undefined ? React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, " 🔑/#️⃣ ", " key"), React.createElement("th", undefined, " 🦒 ", " name"), React.createElement("th", undefined, " 🦇 ", " common name"), React.createElement("th", undefined, " 🦥 ", " species"), React.createElement("th", undefined, " 📋 ", " description"), React.createElement("th", undefined, " 🏷️ ", " image"), React.createElement("th", undefined, " 🧑‍🎨 ", " artist"), React.createElement("th", undefined, " 📸 ", " real images"), React.createElement("th", undefined, " 🏛️ ", " org"))), React.createElement("tbody", undefined, Belt_Array.map(match$2.wildcardData, (function (wildcard) {
                                      var key = wildcard.key;
                                      var keyStr = String(key);
                                      return React.createElement("tr", {
                                                  key: keyStr
                                                }, React.createElement("td", {
                                                      onClick: (function (param) {
                                                          return onClick(keyStr, param);
                                                        })
                                                    }, keyStr, Belt_Option.mapWithDefault(wildcard.nftId, null, (function (id) {
                                                            return React.createElement(React.Fragment, undefined, " (id: ", React.createElement("a", {
                                                                            href: "https://wildcards.world/#details/" + id
                                                                          }, id), ")");
                                                          }))), React.createElement("td", {
                                                      onClick: (function (param) {
                                                          return onClick(keyStr, param);
                                                        })
                                                    }, Belt_Option.getWithDefault(wildcard.name, "")), React.createElement("td", {
                                                      onClick: (function (param) {
                                                          return onClick(keyStr, param);
                                                        })
                                                    }, Belt_Option.getWithDefault(wildcard.commonName, "")), React.createElement("td", {
                                                      onClick: (function (param) {
                                                          return onClick(keyStr, param);
                                                        })
                                                    }, Belt_Option.getWithDefault(wildcard.species, "")), React.createElement("td", undefined, React.createElement(Description.make, {
                                                          description: wildcard.description
                                                        })), React.createElement("td", undefined, React.createElement(CDNImageLink.make, {
                                                          cdnPath: wildcard.image,
                                                          updateAnimation: React.createElement(CardOverview$AddAnimation, {
                                                                imageKey: key
                                                              })
                                                        })), React.createElement("td", undefined, Belt_Option.mapWithDefault(wildcard.artistOfWildcard, "-", (function (param) {
                                                            return React.createElement(React.Fragment, undefined, param.name, Belt_Option.mapWithDefault(param.website, null, (function (website) {
                                                                              return React.createElement("a", {
                                                                                          href: website
                                                                                        }, React.createElement("small", undefined, website));
                                                                            })), Belt_Option.mapWithDefault(param.eth_address, null, (function (ethAddress) {
                                                                              return React.createElement(React.Fragment, undefined, "EthAddr: ", React.createElement("a", {
                                                                                              href: "https://etherscan.io/address/" + ethAddress
                                                                                            }, React.createElement("small", undefined, ethAddress)));
                                                                            })));
                                                          }))), React.createElement("td", undefined, React.createElement(CardOverview$RealImages, {
                                                          imageArray: wildcard.real_wc_photos,
                                                          imageKey: key
                                                        })), React.createElement("td", {
                                                      onClick: (function (param) {
                                                          return onClick(keyStr, param);
                                                        })
                                                    }, Belt_Option.mapWithDefault(wildcard.organization, null, (function (param) {
                                                            return React.createElement("a", {
                                                                        href: "https://wildcards.world/#org/" + param.id
                                                                      }, React.createElement("small", undefined, param.name));
                                                          }))));
                                    })))) : null
                  )
              ));
}

var make = CardOverview;

export {
  animalDescription_decode ,
  $pipe$pipe$pipe$pipe ,
  StringArray ,
  AddRealWildcardImage ,
  AddWildcardAnimation ,
  AddRealImages ,
  AddAnimation ,
  RealImages ,
  YoutubeModal ,
  AddCardsModal ,
  Onboarding ,
  make ,
  
}
/*  Not a pure module */

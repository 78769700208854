// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Queries from "./lib/Queries.bs.js";
import * as Debouncer from "re-debouncer/src/Debouncer.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as Description from "./components/Description.bs.js";
import * as AuthComponent from "./components/AuthComponent.bs.js";
import * as GqlConverters from "./GqlConverters.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "reason-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";
import * as ApolloClient__React_Hooks_UseMutation from "reason-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseMutation.bs.js";

var Raw = {};

var query = (require("@apollo/client").gql`
  mutation ($wildcardsToInsert: [wildcardData_insert_input!]!)  {
    insert_wildcardData(objects: $wildcardsToInsert)  {
      __typename
      returning  {
        __typename
        key
        name
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.insert_wildcardData;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.returning;
    tmp = {
      __typename: value$1.__typename,
      returning: value$2.map(function (value) {
            var value$1 = value.name;
            return {
                    __typename: value.__typename,
                    key: value.key,
                    name: !(value$1 == null) ? value$1 : undefined
                  };
          })
    };
  }
  return {
          insert_wildcardData: tmp
        };
}

function serialize(value) {
  var value$1 = value.insert_wildcardData;
  var insert_wildcardData;
  if (value$1 !== undefined) {
    var value$2 = value$1.returning;
    var returning = value$2.map(function (value) {
          var value$1 = value.name;
          var name = value$1 !== undefined ? value$1 : null;
          var value$2 = value.key;
          var value$3 = value.__typename;
          return {
                  __typename: value$3,
                  key: value$2,
                  name: name
                };
        });
    var value$3 = value$1.__typename;
    insert_wildcardData = {
      __typename: value$3,
      returning: returning
    };
  } else {
    insert_wildcardData = null;
  }
  return {
          insert_wildcardData: insert_wildcardData
        };
}

function serializeInputObjectreal_wc_photos_on_conflict(inp) {
  var a = inp.update_columns;
  var a$1 = inp.where;
  return {
          constraint: "real_wc_photos_pkey",
          update_columns: a.map(function (b) {
                if (b === "wildcardKey") {
                  return "wildcardKey";
                } else if (b === "image") {
                  return "image";
                } else {
                  return "photographer";
                }
              }),
          where: a$1 !== undefined ? serializeInputObjectreal_wc_photos_bool_exp(a$1) : undefined
        };
}

function serializeInputObjectreal_wc_photos_insert_input(inp) {
  var a = inp.image;
  var a$1 = inp.photographer;
  var a$2 = inp.wildcardKey;
  return {
          image: a !== undefined ? a : undefined,
          photographer: a$1 !== undefined ? a$1 : undefined,
          wildcardKey: a$2 !== undefined ? a$2 : undefined
        };
}

function serializeInputObjectorganisations_on_conflict(inp) {
  var a = inp.constraint;
  var a$1 = inp.update_columns;
  var a$2 = inp.where;
  return {
          constraint: a === "organisations_pkey" ? "organisations_pkey" : (
              a === "organisations_announcement_blog_key" ? "organisations_announcement_blog_key" : "organisations_slack_channel_key"
            ),
          update_columns: a$1.map(function (b) {
                if (b === "logo") {
                  return "logo";
                } else if (b === "name") {
                  return "name";
                } else if (b === "logo_badge") {
                  return "logo_badge";
                } else if (b === "onboarding_status") {
                  return "onboarding_status";
                } else if (b === "description") {
                  return "description";
                } else if (b === "website") {
                  return "website";
                } else if (b === "slack_channel") {
                  return "slack_channel";
                } else if (b === "id") {
                  return "id";
                } else if (b === "announcement_blog") {
                  return "announcement_blog";
                } else if (b === "country") {
                  return "country";
                } else if (b === "youtube_vid") {
                  return "youtube_vid";
                } else {
                  return "cover_image";
                }
              }),
          where: a$2 !== undefined ? serializeInputObjectorganisations_bool_exp(a$2) : undefined
        };
}

function serializeInputObjectorganisations_insert_input(inp) {
  var a = inp.announcement_blog;
  var a$1 = inp.country;
  var a$2 = inp.cover_image;
  var a$3 = inp.description;
  var a$4 = inp.id;
  var a$5 = inp.logo;
  var a$6 = inp.logo_badge;
  var a$7 = inp.name;
  var a$8 = inp.onboardingStatus;
  var a$9 = inp.onboarding_status;
  var a$10 = inp.slack_channel;
  var a$11 = inp.website;
  var a$12 = inp.wildcard;
  var a$13 = inp.youtube_vid;
  return {
          announcement_blog: a !== undefined ? a : undefined,
          country: a$1 !== undefined ? a$1 : undefined,
          cover_image: a$2 !== undefined ? a$2 : undefined,
          description: a$3 !== undefined ? Caml_option.valFromOption(a$3) : undefined,
          id: a$4 !== undefined ? a$4 : undefined,
          logo: a$5 !== undefined ? a$5 : undefined,
          logo_badge: a$6 !== undefined ? a$6 : undefined,
          name: a$7 !== undefined ? a$7 : undefined,
          onboardingStatus: a$8 !== undefined ? serializeInputObjectonboarding_status_obj_rel_insert_input(a$8) : undefined,
          onboarding_status: a$9 !== undefined ? (
              a$9 === "declined" ? "declined" : (
                  a$9 === "unprocessed" ? "unprocessed" : (
                      a$9 === "interested" ? "interested" : (
                          a$9 === "listed" ? "listed" : (
                              a$9 === "rejected" ? "rejected" : (
                                  a$9 === "signed" ? "signed" : (
                                      a$9 === "contacted" ? "contacted" : "live"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          slack_channel: a$10 !== undefined ? a$10 : undefined,
          website: a$11 !== undefined ? a$11 : undefined,
          wildcard: a$12 !== undefined ? serializeInputObjectwildcardData_arr_rel_insert_input(a$12) : undefined,
          youtube_vid: a$13 !== undefined ? a$13 : undefined
        };
}

function serializeInputObjectonboarding_status_on_conflict(inp) {
  var a = inp.update_columns;
  var a$1 = inp.where;
  return {
          constraint: "onboarding_status_pkey",
          update_columns: a.map(function (b) {
                return "status";
              }),
          where: a$1 !== undefined ? serializeInputObjectonboarding_status_bool_exp(a$1) : undefined
        };
}

function serializeInputObjectonboarding_status_insert_input(inp) {
  var a = inp.status;
  return {
          status: a !== undefined ? a : undefined
        };
}

function serializeInputObjectwildcardData_bool_exp(inp) {
  var a = inp._and;
  var a$1 = inp._not;
  var a$2 = inp._or;
  var a$3 = inp.artist;
  var a$4 = inp.artistOfWildcard;
  var a$5 = inp.commonName;
  var a$6 = inp.deprecated_id;
  var a$7 = inp.description;
  var a$8 = inp.id;
  var a$9 = inp.image;
  var a$10 = inp.imageIpfsHash;
  var a$11 = inp.ipfsMetadataHash;
  var a$12 = inp.key;
  var a$13 = inp.name;
  var a$14 = inp.nftMetadata;
  var a$15 = inp.organisationId;
  var a$16 = inp.organization;
  var a$17 = inp.real_wc_photos;
  var a$18 = inp.species;
  return {
          _and: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectwildcardData_bool_exp(b);
                  }
                  
                }) : undefined,
          _not: a$1 !== undefined ? serializeInputObjectwildcardData_bool_exp(a$1) : undefined,
          _or: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectwildcardData_bool_exp(b);
                  }
                  
                }) : undefined,
          artist: a$3 !== undefined ? serializeInputObjectString_comparison_exp(a$3) : undefined,
          artistOfWildcard: a$4 !== undefined ? serializeInputObjectartist_bool_exp(a$4) : undefined,
          commonName: a$5 !== undefined ? serializeInputObjectString_comparison_exp(a$5) : undefined,
          deprecated_id: a$6 !== undefined ? serializeInputObjectString_comparison_exp(a$6) : undefined,
          description: a$7 !== undefined ? serializeInputObjectjsonb_comparison_exp(a$7) : undefined,
          id: a$8 !== undefined ? serializeInputObjectString_comparison_exp(a$8) : undefined,
          image: a$9 !== undefined ? serializeInputObjectString_comparison_exp(a$9) : undefined,
          imageIpfsHash: a$10 !== undefined ? serializeInputObjectString_comparison_exp(a$10) : undefined,
          ipfsMetadataHash: a$11 !== undefined ? serializeInputObjectString_comparison_exp(a$11) : undefined,
          key: a$12 !== undefined ? serializeInputObjectInt_comparison_exp(a$12) : undefined,
          name: a$13 !== undefined ? serializeInputObjectString_comparison_exp(a$13) : undefined,
          nftMetadata: a$14 !== undefined ? serializeInputObjectjsonb_comparison_exp(a$14) : undefined,
          organisationId: a$15 !== undefined ? serializeInputObjectString_comparison_exp(a$15) : undefined,
          organization: a$16 !== undefined ? serializeInputObjectorganisations_bool_exp(a$16) : undefined,
          real_wc_photos: a$17 !== undefined ? serializeInputObjectreal_wc_photos_bool_exp(a$17) : undefined,
          species: a$18 !== undefined ? serializeInputObjectString_comparison_exp(a$18) : undefined
        };
}

function serializeInputObjectartist_bool_exp(inp) {
  var a = inp._and;
  var a$1 = inp._not;
  var a$2 = inp._or;
  var a$3 = inp.eth_address;
  var a$4 = inp.id;
  var a$5 = inp.name;
  var a$6 = inp.website;
  return {
          _and: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectartist_bool_exp(b);
                  }
                  
                }) : undefined,
          _not: a$1 !== undefined ? serializeInputObjectartist_bool_exp(a$1) : undefined,
          _or: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectartist_bool_exp(b);
                  }
                  
                }) : undefined,
          eth_address: a$3 !== undefined ? serializeInputObjectString_comparison_exp(a$3) : undefined,
          id: a$4 !== undefined ? serializeInputObjectString_comparison_exp(a$4) : undefined,
          name: a$5 !== undefined ? serializeInputObjectString_comparison_exp(a$5) : undefined,
          website: a$6 !== undefined ? serializeInputObjectString_comparison_exp(a$6) : undefined
        };
}

function serializeInputObjectString_comparison_exp(inp) {
  var a = inp._eq;
  var a$1 = inp._gt;
  var a$2 = inp._gte;
  var a$3 = inp._ilike;
  var a$4 = inp._in;
  var a$5 = inp._is_null;
  var a$6 = inp._like;
  var a$7 = inp._lt;
  var a$8 = inp._lte;
  var a$9 = inp._neq;
  var a$10 = inp._nilike;
  var a$11 = inp._nin;
  var a$12 = inp._nlike;
  var a$13 = inp._nsimilar;
  var a$14 = inp._similar;
  return {
          _eq: a !== undefined ? a : undefined,
          _gt: a$1 !== undefined ? a$1 : undefined,
          _gte: a$2 !== undefined ? a$2 : undefined,
          _ilike: a$3 !== undefined ? a$3 : undefined,
          _in: a$4 !== undefined ? a$4.map(function (b) {
                  return b;
                }) : undefined,
          _is_null: a$5 !== undefined ? a$5 : undefined,
          _like: a$6 !== undefined ? a$6 : undefined,
          _lt: a$7 !== undefined ? a$7 : undefined,
          _lte: a$8 !== undefined ? a$8 : undefined,
          _neq: a$9 !== undefined ? a$9 : undefined,
          _nilike: a$10 !== undefined ? a$10 : undefined,
          _nin: a$11 !== undefined ? a$11.map(function (b) {
                  return b;
                }) : undefined,
          _nlike: a$12 !== undefined ? a$12 : undefined,
          _nsimilar: a$13 !== undefined ? a$13 : undefined,
          _similar: a$14 !== undefined ? a$14 : undefined
        };
}

function serializeInputObjectwildcardData_on_conflict(inp) {
  var a = inp.constraint;
  var a$1 = inp.update_columns;
  var a$2 = inp.where;
  return {
          constraint: a === "wildcardData_key_key" ? "wildcardData_key_key" : (
              a === "wildcardData_deprecated_id_key" ? "wildcardData_deprecated_id_key" : (
                  a === "wildcardData_id_key" ? "wildcardData_id_key" : (
                      a === "wildcardData_pkey" ? "wildcardData_pkey" : "wildcardData_image_key"
                    )
                )
            ),
          update_columns: a$1.map(function (b) {
                if (b === "commonName") {
                  return "commonName";
                } else if (b === "description") {
                  return "description";
                } else if (b === "species") {
                  return "species";
                } else if (b === "ipfsMetadataHash") {
                  return "ipfsMetadataHash";
                } else if (b === "organisationId") {
                  return "organisationId";
                } else if (b === "id") {
                  return "id";
                } else if (b === "key") {
                  return "key";
                } else if (b === "deprecated_id") {
                  return "deprecated_id";
                } else if (b === "nftMetadata") {
                  return "nftMetadata";
                } else if (b === "imageIpfsHash") {
                  return "imageIpfsHash";
                } else if (b === "artist") {
                  return "artist";
                } else if (b === "image") {
                  return "image";
                } else {
                  return "name";
                }
              }),
          where: a$2 !== undefined ? serializeInputObjectwildcardData_bool_exp(a$2) : undefined
        };
}

function serializeInputObjectwildcardData_insert_input(inp) {
  var a = inp.artist;
  var a$1 = inp.artistOfWildcard;
  var a$2 = inp.commonName;
  var a$3 = inp.deprecated_id;
  var a$4 = inp.description;
  var a$5 = inp.id;
  var a$6 = inp.image;
  var a$7 = inp.imageIpfsHash;
  var a$8 = inp.ipfsMetadataHash;
  var a$9 = inp.key;
  var a$10 = inp.name;
  var a$11 = inp.nftMetadata;
  var a$12 = inp.organisationId;
  var a$13 = inp.organization;
  var a$14 = inp.real_wc_photos;
  var a$15 = inp.species;
  return {
          artist: a !== undefined ? a : undefined,
          artistOfWildcard: a$1 !== undefined ? serializeInputObjectartist_obj_rel_insert_input(a$1) : undefined,
          commonName: a$2 !== undefined ? a$2 : undefined,
          deprecated_id: a$3 !== undefined ? a$3 : undefined,
          description: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          id: a$5 !== undefined ? a$5 : undefined,
          image: a$6 !== undefined ? a$6 : undefined,
          imageIpfsHash: a$7 !== undefined ? a$7 : undefined,
          ipfsMetadataHash: a$8 !== undefined ? a$8 : undefined,
          key: a$9 !== undefined ? a$9 : undefined,
          name: a$10 !== undefined ? a$10 : undefined,
          nftMetadata: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          organisationId: a$12 !== undefined ? a$12 : undefined,
          organization: a$13 !== undefined ? serializeInputObjectorganisations_obj_rel_insert_input(a$13) : undefined,
          real_wc_photos: a$14 !== undefined ? serializeInputObjectreal_wc_photos_arr_rel_insert_input(a$14) : undefined,
          species: a$15 !== undefined ? a$15 : undefined
        };
}

function serializeInputObjectorganisations_bool_exp(inp) {
  var a = inp._and;
  var a$1 = inp._not;
  var a$2 = inp._or;
  var a$3 = inp.announcement_blog;
  var a$4 = inp.country;
  var a$5 = inp.cover_image;
  var a$6 = inp.description;
  var a$7 = inp.id;
  var a$8 = inp.logo;
  var a$9 = inp.logo_badge;
  var a$10 = inp.name;
  var a$11 = inp.onboardingStatus;
  var a$12 = inp.onboarding_status;
  var a$13 = inp.slack_channel;
  var a$14 = inp.website;
  var a$15 = inp.wildcard;
  var a$16 = inp.youtube_vid;
  return {
          _and: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectorganisations_bool_exp(b);
                  }
                  
                }) : undefined,
          _not: a$1 !== undefined ? serializeInputObjectorganisations_bool_exp(a$1) : undefined,
          _or: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectorganisations_bool_exp(b);
                  }
                  
                }) : undefined,
          announcement_blog: a$3 !== undefined ? serializeInputObjectString_comparison_exp(a$3) : undefined,
          country: a$4 !== undefined ? serializeInputObjectString_comparison_exp(a$4) : undefined,
          cover_image: a$5 !== undefined ? serializeInputObjectString_comparison_exp(a$5) : undefined,
          description: a$6 !== undefined ? serializeInputObjectjsonb_comparison_exp(a$6) : undefined,
          id: a$7 !== undefined ? serializeInputObjectString_comparison_exp(a$7) : undefined,
          logo: a$8 !== undefined ? serializeInputObjectString_comparison_exp(a$8) : undefined,
          logo_badge: a$9 !== undefined ? serializeInputObjectString_comparison_exp(a$9) : undefined,
          name: a$10 !== undefined ? serializeInputObjectString_comparison_exp(a$10) : undefined,
          onboardingStatus: a$11 !== undefined ? serializeInputObjectonboarding_status_bool_exp(a$11) : undefined,
          onboarding_status: a$12 !== undefined ? serializeInputObjectonboarding_status_enum_comparison_exp(a$12) : undefined,
          slack_channel: a$13 !== undefined ? serializeInputObjectString_comparison_exp(a$13) : undefined,
          website: a$14 !== undefined ? serializeInputObjectString_comparison_exp(a$14) : undefined,
          wildcard: a$15 !== undefined ? serializeInputObjectwildcardData_bool_exp(a$15) : undefined,
          youtube_vid: a$16 !== undefined ? serializeInputObjectString_comparison_exp(a$16) : undefined
        };
}

function serializeInputObjectonboarding_status_bool_exp(inp) {
  var a = inp._and;
  var a$1 = inp._not;
  var a$2 = inp._or;
  var a$3 = inp.status;
  return {
          _and: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectonboarding_status_bool_exp(b);
                  }
                  
                }) : undefined,
          _not: a$1 !== undefined ? serializeInputObjectonboarding_status_bool_exp(a$1) : undefined,
          _or: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectonboarding_status_bool_exp(b);
                  }
                  
                }) : undefined,
          status: a$3 !== undefined ? serializeInputObjectString_comparison_exp(a$3) : undefined
        };
}

function serializeInputObjectartist_on_conflict(inp) {
  var a = inp.constraint;
  var a$1 = inp.update_columns;
  var a$2 = inp.where;
  return {
          constraint: a === "artist_eth_address_key" ? "artist_eth_address_key" : (
              a === "artist_pkey" ? "artist_pkey" : (
                  a === "artist_website_key" ? "artist_website_key" : "artist_name_key"
                )
            ),
          update_columns: a$1.map(function (b) {
                if (b === "website") {
                  return "website";
                } else if (b === "id") {
                  return "id";
                } else if (b === "eth_address") {
                  return "eth_address";
                } else {
                  return "name";
                }
              }),
          where: a$2 !== undefined ? serializeInputObjectartist_bool_exp(a$2) : undefined
        };
}

function serializeInputObjectartist_insert_input(inp) {
  var a = inp.eth_address;
  var a$1 = inp.id;
  var a$2 = inp.name;
  var a$3 = inp.website;
  return {
          eth_address: a !== undefined ? a : undefined,
          id: a$1 !== undefined ? a$1 : undefined,
          name: a$2 !== undefined ? a$2 : undefined,
          website: a$3 !== undefined ? a$3 : undefined
        };
}

function serializeInputObjectwildcardData_arr_rel_insert_input(inp) {
  var a = inp.data;
  var a$1 = inp.on_conflict;
  return {
          data: a.map(serializeInputObjectwildcardData_insert_input),
          on_conflict: a$1 !== undefined ? serializeInputObjectwildcardData_on_conflict(a$1) : undefined
        };
}

function serializeInputObjectonboarding_status_obj_rel_insert_input(inp) {
  var a = inp.on_conflict;
  return {
          data: serializeInputObjectonboarding_status_insert_input(inp.data),
          on_conflict: a !== undefined ? serializeInputObjectonboarding_status_on_conflict(a) : undefined
        };
}

function serializeInputObjectjsonb_comparison_exp(inp) {
  var a = inp._contained_in;
  var a$1 = inp._contains;
  var a$2 = inp._eq;
  var a$3 = inp._gt;
  var a$4 = inp._gte;
  var a$5 = inp._has_key;
  var a$6 = inp._has_keys_all;
  var a$7 = inp._has_keys_any;
  var a$8 = inp._in;
  var a$9 = inp._is_null;
  var a$10 = inp._lt;
  var a$11 = inp._lte;
  var a$12 = inp._neq;
  var a$13 = inp._nin;
  return {
          _contained_in: a !== undefined ? Caml_option.valFromOption(a) : undefined,
          _contains: a$1 !== undefined ? Caml_option.valFromOption(a$1) : undefined,
          _eq: a$2 !== undefined ? Caml_option.valFromOption(a$2) : undefined,
          _gt: a$3 !== undefined ? Caml_option.valFromOption(a$3) : undefined,
          _gte: a$4 !== undefined ? Caml_option.valFromOption(a$4) : undefined,
          _has_key: a$5 !== undefined ? a$5 : undefined,
          _has_keys_all: a$6 !== undefined ? a$6.map(function (b) {
                  return b;
                }) : undefined,
          _has_keys_any: a$7 !== undefined ? a$7.map(function (b) {
                  return b;
                }) : undefined,
          _in: a$8 !== undefined ? a$8.map(function (b) {
                  return b;
                }) : undefined,
          _is_null: a$9 !== undefined ? a$9 : undefined,
          _lt: a$10 !== undefined ? Caml_option.valFromOption(a$10) : undefined,
          _lte: a$11 !== undefined ? Caml_option.valFromOption(a$11) : undefined,
          _neq: a$12 !== undefined ? Caml_option.valFromOption(a$12) : undefined,
          _nin: a$13 !== undefined ? a$13.map(function (b) {
                  return b;
                }) : undefined
        };
}

function serializeInputObjectInt_comparison_exp(inp) {
  var a = inp._eq;
  var a$1 = inp._gt;
  var a$2 = inp._gte;
  var a$3 = inp._in;
  var a$4 = inp._is_null;
  var a$5 = inp._lt;
  var a$6 = inp._lte;
  var a$7 = inp._neq;
  var a$8 = inp._nin;
  return {
          _eq: a !== undefined ? a : undefined,
          _gt: a$1 !== undefined ? a$1 : undefined,
          _gte: a$2 !== undefined ? a$2 : undefined,
          _in: a$3 !== undefined ? a$3.map(function (b) {
                  return b;
                }) : undefined,
          _is_null: a$4 !== undefined ? a$4 : undefined,
          _lt: a$5 !== undefined ? a$5 : undefined,
          _lte: a$6 !== undefined ? a$6 : undefined,
          _neq: a$7 !== undefined ? a$7 : undefined,
          _nin: a$8 !== undefined ? a$8.map(function (b) {
                  return b;
                }) : undefined
        };
}

function serializeInputObjectreal_wc_photos_bool_exp(inp) {
  var a = inp._and;
  var a$1 = inp._not;
  var a$2 = inp._or;
  var a$3 = inp.image;
  var a$4 = inp.photographer;
  var a$5 = inp.wildcardKey;
  return {
          _and: a !== undefined ? a.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectreal_wc_photos_bool_exp(b);
                  }
                  
                }) : undefined,
          _not: a$1 !== undefined ? serializeInputObjectreal_wc_photos_bool_exp(a$1) : undefined,
          _or: a$2 !== undefined ? a$2.map(function (b) {
                  if (b !== undefined) {
                    return serializeInputObjectreal_wc_photos_bool_exp(b);
                  }
                  
                }) : undefined,
          image: a$3 !== undefined ? serializeInputObjectString_comparison_exp(a$3) : undefined,
          photographer: a$4 !== undefined ? serializeInputObjectString_comparison_exp(a$4) : undefined,
          wildcardKey: a$5 !== undefined ? serializeInputObjectInt_comparison_exp(a$5) : undefined
        };
}

function serializeInputObjectartist_obj_rel_insert_input(inp) {
  var a = inp.on_conflict;
  return {
          data: serializeInputObjectartist_insert_input(inp.data),
          on_conflict: a !== undefined ? serializeInputObjectartist_on_conflict(a) : undefined
        };
}

function serializeInputObjectreal_wc_photos_arr_rel_insert_input(inp) {
  var a = inp.data;
  var a$1 = inp.on_conflict;
  return {
          data: a.map(serializeInputObjectreal_wc_photos_insert_input),
          on_conflict: a$1 !== undefined ? serializeInputObjectreal_wc_photos_on_conflict(a$1) : undefined
        };
}

function serializeInputObjectorganisations_obj_rel_insert_input(inp) {
  var a = inp.on_conflict;
  return {
          data: serializeInputObjectorganisations_insert_input(inp.data),
          on_conflict: a !== undefined ? serializeInputObjectorganisations_on_conflict(a) : undefined
        };
}

function serializeInputObjectonboarding_status_enum_comparison_exp(inp) {
  var a = inp._eq;
  var a$1 = inp._in;
  var a$2 = inp._is_null;
  var a$3 = inp._neq;
  var a$4 = inp._nin;
  return {
          _eq: a !== undefined ? (
              a === "declined" ? "declined" : (
                  a === "unprocessed" ? "unprocessed" : (
                      a === "interested" ? "interested" : (
                          a === "listed" ? "listed" : (
                              a === "rejected" ? "rejected" : (
                                  a === "signed" ? "signed" : (
                                      a === "contacted" ? "contacted" : "live"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          _in: a$1 !== undefined ? a$1.map(function (b) {
                  if (b === "declined") {
                    return "declined";
                  } else if (b === "unprocessed") {
                    return "unprocessed";
                  } else if (b === "interested") {
                    return "interested";
                  } else if (b === "listed") {
                    return "listed";
                  } else if (b === "rejected") {
                    return "rejected";
                  } else if (b === "signed") {
                    return "signed";
                  } else if (b === "contacted") {
                    return "contacted";
                  } else {
                    return "live";
                  }
                }) : undefined,
          _is_null: a$2 !== undefined ? a$2 : undefined,
          _neq: a$3 !== undefined ? (
              a$3 === "declined" ? "declined" : (
                  a$3 === "unprocessed" ? "unprocessed" : (
                      a$3 === "interested" ? "interested" : (
                          a$3 === "listed" ? "listed" : (
                              a$3 === "rejected" ? "rejected" : (
                                  a$3 === "signed" ? "signed" : (
                                      a$3 === "contacted" ? "contacted" : "live"
                                    )
                                )
                            )
                        )
                    )
                )
            ) : undefined,
          _nin: a$4 !== undefined ? a$4.map(function (b) {
                  if (b === "declined") {
                    return "declined";
                  } else if (b === "unprocessed") {
                    return "unprocessed";
                  } else if (b === "interested") {
                    return "interested";
                  } else if (b === "listed") {
                    return "listed";
                  } else if (b === "rejected") {
                    return "rejected";
                  } else if (b === "signed") {
                    return "signed";
                  } else if (b === "contacted") {
                    return "contacted";
                  } else {
                    return "live";
                  }
                }) : undefined
        };
}

function serializeVariables(inp) {
  var a = inp.wildcardsToInsert;
  return {
          wildcardsToInsert: a.map(serializeInputObjectwildcardData_insert_input)
        };
}

function makeVariables(wildcardsToInsert, param) {
  return {
          wildcardsToInsert: wildcardsToInsert
        };
}

function makeInputObjectwildcardData_insert_input(artist, artistOfWildcard, commonName, deprecated_id, description, id, image, imageIpfsHash, ipfsMetadataHash, key, name, nftMetadata, organisationId, organization, real_wc_photos, species, param) {
  return {
          artist: artist,
          artistOfWildcard: artistOfWildcard,
          commonName: commonName,
          deprecated_id: deprecated_id,
          description: description,
          id: id,
          image: image,
          imageIpfsHash: imageIpfsHash,
          ipfsMetadataHash: ipfsMetadataHash,
          key: key,
          name: name,
          nftMetadata: nftMetadata,
          organisationId: organisationId,
          organization: organization,
          real_wc_photos: real_wc_photos,
          species: species
        };
}

function makeInputObjectartist_obj_rel_insert_input(data, on_conflict, param) {
  return {
          data: data,
          on_conflict: on_conflict
        };
}

function makeInputObjectartist_insert_input(eth_address, id, name, website, param) {
  return {
          eth_address: eth_address,
          id: id,
          name: name,
          website: website
        };
}

function makeInputObjectartist_on_conflict(constraint_, update_columns, where, param) {
  return {
          constraint: constraint_,
          update_columns: update_columns,
          where: where
        };
}

function makeInputObjectartist_bool_exp(_and, _not, _or, eth_address, id, name, website, param) {
  return {
          _and: _and,
          _not: _not,
          _or: _or,
          eth_address: eth_address,
          id: id,
          name: name,
          website: website
        };
}

function makeInputObjectString_comparison_exp(_eq, _gt, _gte, _ilike, _in, _is_null, _like, _lt, _lte, _neq, _nilike, _nin, _nlike, _nsimilar, _similar, param) {
  return {
          _eq: _eq,
          _gt: _gt,
          _gte: _gte,
          _ilike: _ilike,
          _in: _in,
          _is_null: _is_null,
          _like: _like,
          _lt: _lt,
          _lte: _lte,
          _neq: _neq,
          _nilike: _nilike,
          _nin: _nin,
          _nlike: _nlike,
          _nsimilar: _nsimilar,
          _similar: _similar
        };
}

function makeInputObjectorganisations_obj_rel_insert_input(data, on_conflict, param) {
  return {
          data: data,
          on_conflict: on_conflict
        };
}

function makeInputObjectorganisations_insert_input(announcement_blog, country, cover_image, description, id, logo, logo_badge, name, onboardingStatus, onboarding_status, slack_channel, website, wildcard, youtube_vid, param) {
  return {
          announcement_blog: announcement_blog,
          country: country,
          cover_image: cover_image,
          description: description,
          id: id,
          logo: logo,
          logo_badge: logo_badge,
          name: name,
          onboardingStatus: onboardingStatus,
          onboarding_status: onboarding_status,
          slack_channel: slack_channel,
          website: website,
          wildcard: wildcard,
          youtube_vid: youtube_vid
        };
}

function makeInputObjectonboarding_status_obj_rel_insert_input(data, on_conflict, param) {
  return {
          data: data,
          on_conflict: on_conflict
        };
}

function makeInputObjectonboarding_status_insert_input(status, param) {
  return {
          status: status
        };
}

function makeInputObjectonboarding_status_on_conflict(constraint_, update_columns, where, param) {
  return {
          constraint: constraint_,
          update_columns: update_columns,
          where: where
        };
}

function makeInputObjectonboarding_status_bool_exp(_and, _not, _or, status, param) {
  return {
          _and: _and,
          _not: _not,
          _or: _or,
          status: status
        };
}

function makeInputObjectwildcardData_arr_rel_insert_input(data, on_conflict, param) {
  return {
          data: data,
          on_conflict: on_conflict
        };
}

function makeInputObjectwildcardData_on_conflict(constraint_, update_columns, where, param) {
  return {
          constraint: constraint_,
          update_columns: update_columns,
          where: where
        };
}

function makeInputObjectwildcardData_bool_exp(_and, _not, _or, artist, artistOfWildcard, commonName, deprecated_id, description, id, image, imageIpfsHash, ipfsMetadataHash, key, name, nftMetadata, organisationId, organization, real_wc_photos, species, param) {
  return {
          _and: _and,
          _not: _not,
          _or: _or,
          artist: artist,
          artistOfWildcard: artistOfWildcard,
          commonName: commonName,
          deprecated_id: deprecated_id,
          description: description,
          id: id,
          image: image,
          imageIpfsHash: imageIpfsHash,
          ipfsMetadataHash: ipfsMetadataHash,
          key: key,
          name: name,
          nftMetadata: nftMetadata,
          organisationId: organisationId,
          organization: organization,
          real_wc_photos: real_wc_photos,
          species: species
        };
}

function makeInputObjectjsonb_comparison_exp(_contained_in, _contains, _eq, _gt, _gte, _has_key, _has_keys_all, _has_keys_any, _in, _is_null, _lt, _lte, _neq, _nin, param) {
  return {
          _contained_in: _contained_in,
          _contains: _contains,
          _eq: _eq,
          _gt: _gt,
          _gte: _gte,
          _has_key: _has_key,
          _has_keys_all: _has_keys_all,
          _has_keys_any: _has_keys_any,
          _in: _in,
          _is_null: _is_null,
          _lt: _lt,
          _lte: _lte,
          _neq: _neq,
          _nin: _nin
        };
}

function makeInputObjectInt_comparison_exp(_eq, _gt, _gte, _in, _is_null, _lt, _lte, _neq, _nin, param) {
  return {
          _eq: _eq,
          _gt: _gt,
          _gte: _gte,
          _in: _in,
          _is_null: _is_null,
          _lt: _lt,
          _lte: _lte,
          _neq: _neq,
          _nin: _nin
        };
}

function makeInputObjectorganisations_bool_exp(_and, _not, _or, announcement_blog, country, cover_image, description, id, logo, logo_badge, name, onboardingStatus, onboarding_status, slack_channel, website, wildcard, youtube_vid, param) {
  return {
          _and: _and,
          _not: _not,
          _or: _or,
          announcement_blog: announcement_blog,
          country: country,
          cover_image: cover_image,
          description: description,
          id: id,
          logo: logo,
          logo_badge: logo_badge,
          name: name,
          onboardingStatus: onboardingStatus,
          onboarding_status: onboarding_status,
          slack_channel: slack_channel,
          website: website,
          wildcard: wildcard,
          youtube_vid: youtube_vid
        };
}

function makeInputObjectonboarding_status_enum_comparison_exp(_eq, _in, _is_null, _neq, _nin, param) {
  return {
          _eq: _eq,
          _in: _in,
          _is_null: _is_null,
          _neq: _neq,
          _nin: _nin
        };
}

function makeInputObjectreal_wc_photos_bool_exp(_and, _not, _or, image, photographer, wildcardKey, param) {
  return {
          _and: _and,
          _not: _not,
          _or: _or,
          image: image,
          photographer: photographer,
          wildcardKey: wildcardKey
        };
}

function makeInputObjectorganisations_on_conflict(constraint_, update_columns, where, param) {
  return {
          constraint: constraint_,
          update_columns: update_columns,
          where: where
        };
}

function makeInputObjectreal_wc_photos_arr_rel_insert_input(data, on_conflict, param) {
  return {
          data: data,
          on_conflict: on_conflict
        };
}

function makeInputObjectreal_wc_photos_insert_input(image, photographer, wildcardKey, param) {
  return {
          image: image,
          photographer: photographer,
          wildcardKey: wildcardKey
        };
}

function makeInputObjectreal_wc_photos_on_conflict(constraint_, update_columns, where, param) {
  return {
          constraint: constraint_,
          update_columns: update_columns,
          where: where
        };
}

var AddWildcards_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectwildcardData_insert_input: serializeInputObjectwildcardData_insert_input,
  serializeInputObjectartist_obj_rel_insert_input: serializeInputObjectartist_obj_rel_insert_input,
  serializeInputObjectartist_insert_input: serializeInputObjectartist_insert_input,
  serializeInputObjectartist_on_conflict: serializeInputObjectartist_on_conflict,
  serializeInputObjectartist_bool_exp: serializeInputObjectartist_bool_exp,
  serializeInputObjectString_comparison_exp: serializeInputObjectString_comparison_exp,
  serializeInputObjectorganisations_obj_rel_insert_input: serializeInputObjectorganisations_obj_rel_insert_input,
  serializeInputObjectorganisations_insert_input: serializeInputObjectorganisations_insert_input,
  serializeInputObjectonboarding_status_obj_rel_insert_input: serializeInputObjectonboarding_status_obj_rel_insert_input,
  serializeInputObjectonboarding_status_insert_input: serializeInputObjectonboarding_status_insert_input,
  serializeInputObjectonboarding_status_on_conflict: serializeInputObjectonboarding_status_on_conflict,
  serializeInputObjectonboarding_status_bool_exp: serializeInputObjectonboarding_status_bool_exp,
  serializeInputObjectwildcardData_arr_rel_insert_input: serializeInputObjectwildcardData_arr_rel_insert_input,
  serializeInputObjectwildcardData_on_conflict: serializeInputObjectwildcardData_on_conflict,
  serializeInputObjectwildcardData_bool_exp: serializeInputObjectwildcardData_bool_exp,
  serializeInputObjectjsonb_comparison_exp: serializeInputObjectjsonb_comparison_exp,
  serializeInputObjectInt_comparison_exp: serializeInputObjectInt_comparison_exp,
  serializeInputObjectorganisations_bool_exp: serializeInputObjectorganisations_bool_exp,
  serializeInputObjectonboarding_status_enum_comparison_exp: serializeInputObjectonboarding_status_enum_comparison_exp,
  serializeInputObjectreal_wc_photos_bool_exp: serializeInputObjectreal_wc_photos_bool_exp,
  serializeInputObjectorganisations_on_conflict: serializeInputObjectorganisations_on_conflict,
  serializeInputObjectreal_wc_photos_arr_rel_insert_input: serializeInputObjectreal_wc_photos_arr_rel_insert_input,
  serializeInputObjectreal_wc_photos_insert_input: serializeInputObjectreal_wc_photos_insert_input,
  serializeInputObjectreal_wc_photos_on_conflict: serializeInputObjectreal_wc_photos_on_conflict,
  makeVariables: makeVariables,
  makeInputObjectwildcardData_insert_input: makeInputObjectwildcardData_insert_input,
  makeInputObjectartist_obj_rel_insert_input: makeInputObjectartist_obj_rel_insert_input,
  makeInputObjectartist_insert_input: makeInputObjectartist_insert_input,
  makeInputObjectartist_on_conflict: makeInputObjectartist_on_conflict,
  makeInputObjectartist_bool_exp: makeInputObjectartist_bool_exp,
  makeInputObjectString_comparison_exp: makeInputObjectString_comparison_exp,
  makeInputObjectorganisations_obj_rel_insert_input: makeInputObjectorganisations_obj_rel_insert_input,
  makeInputObjectorganisations_insert_input: makeInputObjectorganisations_insert_input,
  makeInputObjectonboarding_status_obj_rel_insert_input: makeInputObjectonboarding_status_obj_rel_insert_input,
  makeInputObjectonboarding_status_insert_input: makeInputObjectonboarding_status_insert_input,
  makeInputObjectonboarding_status_on_conflict: makeInputObjectonboarding_status_on_conflict,
  makeInputObjectonboarding_status_bool_exp: makeInputObjectonboarding_status_bool_exp,
  makeInputObjectwildcardData_arr_rel_insert_input: makeInputObjectwildcardData_arr_rel_insert_input,
  makeInputObjectwildcardData_on_conflict: makeInputObjectwildcardData_on_conflict,
  makeInputObjectwildcardData_bool_exp: makeInputObjectwildcardData_bool_exp,
  makeInputObjectjsonb_comparison_exp: makeInputObjectjsonb_comparison_exp,
  makeInputObjectInt_comparison_exp: makeInputObjectInt_comparison_exp,
  makeInputObjectorganisations_bool_exp: makeInputObjectorganisations_bool_exp,
  makeInputObjectonboarding_status_enum_comparison_exp: makeInputObjectonboarding_status_enum_comparison_exp,
  makeInputObjectreal_wc_photos_bool_exp: makeInputObjectreal_wc_photos_bool_exp,
  makeInputObjectorganisations_on_conflict: makeInputObjectorganisations_on_conflict,
  makeInputObjectreal_wc_photos_arr_rel_insert_input: makeInputObjectreal_wc_photos_arr_rel_insert_input,
  makeInputObjectreal_wc_photos_insert_input: makeInputObjectreal_wc_photos_insert_input,
  makeInputObjectreal_wc_photos_on_conflict: makeInputObjectreal_wc_photos_on_conflict
};

var include = ApolloClient__React_Hooks_UseMutation.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AddWildcards_useWithVariables = include.useWithVariables;

var AddWildcards = {
  AddWildcards_inner: AddWildcards_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  serializeInputObjectwildcardData_insert_input: serializeInputObjectwildcardData_insert_input,
  serializeInputObjectartist_obj_rel_insert_input: serializeInputObjectartist_obj_rel_insert_input,
  serializeInputObjectartist_insert_input: serializeInputObjectartist_insert_input,
  serializeInputObjectartist_on_conflict: serializeInputObjectartist_on_conflict,
  serializeInputObjectartist_bool_exp: serializeInputObjectartist_bool_exp,
  serializeInputObjectString_comparison_exp: serializeInputObjectString_comparison_exp,
  serializeInputObjectorganisations_obj_rel_insert_input: serializeInputObjectorganisations_obj_rel_insert_input,
  serializeInputObjectorganisations_insert_input: serializeInputObjectorganisations_insert_input,
  serializeInputObjectonboarding_status_obj_rel_insert_input: serializeInputObjectonboarding_status_obj_rel_insert_input,
  serializeInputObjectonboarding_status_insert_input: serializeInputObjectonboarding_status_insert_input,
  serializeInputObjectonboarding_status_on_conflict: serializeInputObjectonboarding_status_on_conflict,
  serializeInputObjectonboarding_status_bool_exp: serializeInputObjectonboarding_status_bool_exp,
  serializeInputObjectwildcardData_arr_rel_insert_input: serializeInputObjectwildcardData_arr_rel_insert_input,
  serializeInputObjectwildcardData_on_conflict: serializeInputObjectwildcardData_on_conflict,
  serializeInputObjectwildcardData_bool_exp: serializeInputObjectwildcardData_bool_exp,
  serializeInputObjectjsonb_comparison_exp: serializeInputObjectjsonb_comparison_exp,
  serializeInputObjectInt_comparison_exp: serializeInputObjectInt_comparison_exp,
  serializeInputObjectorganisations_bool_exp: serializeInputObjectorganisations_bool_exp,
  serializeInputObjectonboarding_status_enum_comparison_exp: serializeInputObjectonboarding_status_enum_comparison_exp,
  serializeInputObjectreal_wc_photos_bool_exp: serializeInputObjectreal_wc_photos_bool_exp,
  serializeInputObjectorganisations_on_conflict: serializeInputObjectorganisations_on_conflict,
  serializeInputObjectreal_wc_photos_arr_rel_insert_input: serializeInputObjectreal_wc_photos_arr_rel_insert_input,
  serializeInputObjectreal_wc_photos_insert_input: serializeInputObjectreal_wc_photos_insert_input,
  serializeInputObjectreal_wc_photos_on_conflict: serializeInputObjectreal_wc_photos_on_conflict,
  makeVariables: makeVariables,
  makeInputObjectwildcardData_insert_input: makeInputObjectwildcardData_insert_input,
  makeInputObjectartist_obj_rel_insert_input: makeInputObjectartist_obj_rel_insert_input,
  makeInputObjectartist_insert_input: makeInputObjectartist_insert_input,
  makeInputObjectartist_on_conflict: makeInputObjectartist_on_conflict,
  makeInputObjectartist_bool_exp: makeInputObjectartist_bool_exp,
  makeInputObjectString_comparison_exp: makeInputObjectString_comparison_exp,
  makeInputObjectorganisations_obj_rel_insert_input: makeInputObjectorganisations_obj_rel_insert_input,
  makeInputObjectorganisations_insert_input: makeInputObjectorganisations_insert_input,
  makeInputObjectonboarding_status_obj_rel_insert_input: makeInputObjectonboarding_status_obj_rel_insert_input,
  makeInputObjectonboarding_status_insert_input: makeInputObjectonboarding_status_insert_input,
  makeInputObjectonboarding_status_on_conflict: makeInputObjectonboarding_status_on_conflict,
  makeInputObjectonboarding_status_bool_exp: makeInputObjectonboarding_status_bool_exp,
  makeInputObjectwildcardData_arr_rel_insert_input: makeInputObjectwildcardData_arr_rel_insert_input,
  makeInputObjectwildcardData_on_conflict: makeInputObjectwildcardData_on_conflict,
  makeInputObjectwildcardData_bool_exp: makeInputObjectwildcardData_bool_exp,
  makeInputObjectjsonb_comparison_exp: makeInputObjectjsonb_comparison_exp,
  makeInputObjectInt_comparison_exp: makeInputObjectInt_comparison_exp,
  makeInputObjectorganisations_bool_exp: makeInputObjectorganisations_bool_exp,
  makeInputObjectonboarding_status_enum_comparison_exp: makeInputObjectonboarding_status_enum_comparison_exp,
  makeInputObjectreal_wc_photos_bool_exp: makeInputObjectreal_wc_photos_bool_exp,
  makeInputObjectorganisations_on_conflict: makeInputObjectorganisations_on_conflict,
  makeInputObjectreal_wc_photos_arr_rel_insert_input: makeInputObjectreal_wc_photos_arr_rel_insert_input,
  makeInputObjectreal_wc_photos_insert_input: makeInputObjectreal_wc_photos_insert_input,
  makeInputObjectreal_wc_photos_on_conflict: makeInputObjectreal_wc_photos_on_conflict,
  use: use,
  useWithVariables: AddWildcards_useWithVariables
};

var Raw$1 = {};

var query$1 = (require("@apollo/client").gql`
  query MyQuery  {
    organisations  {
      __typename
      id
    }
  }
`);

function parse$1(value) {
  var value$1 = value.organisations;
  return {
          organisations: value$1.map(function (value) {
                return {
                        __typename: value.__typename,
                        id: value.id
                      };
              })
        };
}

function serialize$1(value) {
  var value$1 = value.organisations;
  var organisations = value$1.map(function (value) {
        var value$1 = value.id;
        var value$2 = value.__typename;
        return {
                __typename: value$2,
                id: value$1
              };
      });
  return {
          organisations: organisations
        };
}

function serializeVariables$1(param) {
  
}

function makeVariables$1(param) {
  
}

function makeDefaultVariables(param) {
  
}

var AllOrgIds_inner = {
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables
};

var include$1 = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query$1,
      Raw: Raw$1,
      parse: parse$1,
      serialize: serialize$1,
      serializeVariables: serializeVariables$1
    });

var use$1 = include$1.use;

var AllOrgIds_refetchQueryDescription = include$1.refetchQueryDescription;

var AllOrgIds_useLazy = include$1.useLazy;

var AllOrgIds_useLazyWithVariables = include$1.useLazyWithVariables;

var AllOrgIds = {
  AllOrgIds_inner: AllOrgIds_inner,
  Raw: Raw$1,
  query: query$1,
  parse: parse$1,
  serialize: serialize$1,
  serializeVariables: serializeVariables$1,
  makeVariables: makeVariables$1,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: AllOrgIds_refetchQueryDescription,
  use: use$1,
  useLazy: AllOrgIds_useLazy,
  useLazyWithVariables: AllOrgIds_useLazyWithVariables
};

var emptyNewCard = {
  artist: undefined,
  artistOfWildcard: undefined,
  commonName: undefined,
  deprecated_id: undefined,
  description: undefined,
  id: undefined,
  image: undefined,
  imageIpfsHash: undefined,
  ipfsMetadataHash: undefined,
  key: undefined,
  name: undefined,
  nftMetadata: undefined,
  organisationId: undefined,
  organization: undefined,
  real_wc_photos: undefined,
  species: undefined
};

var stringToParagraphs = Debouncer.make(undefined, (function (param) {
        var input = param[0];
        input.includes("\xe2\x80\x9c") || input.includes("\xe2\x80\x9d") || input.includes("\xe2\x80\x98") || input.includes("\xe2\x80\x99");
        var lines = input.split("\n");
        var trimmedLines = Belt_Array.map(lines, (function (prim) {
                return prim.trim();
              }));
        return Curry._1(param[1], Belt_Array.keep(trimmedLines, (function (paragraph) {
                          return paragraph !== "";
                        })));
      }));

function AddWildcards$SetParagarphArrayField(Props) {
  var lable = Props.lable;
  var currentValue = Props.currentValue;
  var setValue = Props.setValue;
  var close = Props.close;
  var match = React.useState(function () {
        return currentValue.join("\n\n");
      });
  var setTextareaValue = match[1];
  var textareaValue = match[0];
  console.log(textareaValue);
  React.useEffect((function () {
          Curry._1(stringToParagraphs, [
                textareaValue,
                setValue
              ]);
          
        }), [textareaValue]);
  return React.createElement(React.Fragment, undefined, React.createElement("td", {
                  colSpan: 4
                }, React.createElement("label", undefined, lable), React.createElement("br", undefined), React.createElement("textarea", {
                      className: Curry._1(Css.style, {
                            hd: Css.width({
                                  NAME: "percent",
                                  VAL: 100
                                }),
                            tl: {
                              hd: Css.height(Css.em(25)),
                              tl: /* [] */0
                            }
                          }),
                      value: textareaValue,
                      onChange: (function (e) {
                          return Curry._1(setTextareaValue, e.target.value);
                        })
                    }), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("button", {
                      className: Curry._1(Css.style, {
                            hd: Css.color(Css.white),
                            tl: /* [] */0
                          }),
                      onClick: close
                    }, "Save and continue")), React.createElement("td", {
                  colSpan: 4
                }, Belt_Array.mapWithIndex(currentValue, (function (index, paragraph) {
                        return React.createElement(React.Fragment, undefined, React.createElement("small", {
                                        className: Curry._1(Css.style, {
                                              hd: Css.color(Css.grey),
                                              tl: /* [] */0
                                            })
                                      }, "Paragraph #" + String(index)), React.createElement("p", undefined, paragraph));
                      }))));
}

var SetParagarphArrayField = {
  make: AddWildcards$SetParagarphArrayField
};

function AddWildcards$SetStringField(Props) {
  var lable = Props.lable;
  var currentValue = Props.currentValue;
  var setValue = Props.setValue;
  var close = Props.close;
  return React.createElement(React.Fragment, undefined, React.createElement("label", undefined, lable), React.createElement("br", undefined), React.createElement("input", {
                  type: "text",
                  value: currentValue,
                  onChange: (function (e) {
                      return Curry._1(setValue, e.target.value);
                    })
                }), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("button", {
                  className: Curry._1(Css.style, {
                        hd: Css.color(Css.white),
                        tl: /* [] */0
                      }),
                  onClick: close
                }, "Finish"));
}

var SetStringField = {
  make: AddWildcards$SetStringField
};

function AddWildcards$SelectOrganisation(Props) {
  var lable = Props.lable;
  var currentValue = Props.currentValue;
  var setValue = Props.setValue;
  var close = Props.close;
  var allOrgsQuery = Curry.app(use$1, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var match = allOrgsQuery.data;
  var error = allOrgsQuery.error;
  return React.createElement(React.Fragment, undefined, allOrgsQuery.loading ? "Loading list of all organisations..." : (
                error !== undefined ? React.createElement(AuthComponent.make, {
                        children: "Error loading data - message: " + (error.message + "\n\n(copy paste the message to Jason on slack, but try reload the page first)")
                      }) : (
                    match !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("label", undefined, lable), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("select", {
                                value: currentValue,
                                onChange: (function (e) {
                                    return Curry._1(setValue, e.target.value);
                                  })
                              }, Belt_Array.map(match.organisations, (function (param) {
                                      var id = param.id;
                                      return React.createElement("option", {
                                                  value: id
                                                }, id);
                                    }))), React.createElement("br", undefined), React.createElement("button", {
                                className: Curry._1(Css.style, {
                                      hd: Css.color(Css.white),
                                      tl: /* [] */0
                                    }),
                                onClick: close
                              }, "Finish")) : null
                  )
              ));
}

var SelectOrganisation = {
  make: AddWildcards$SelectOrganisation
};

function AddWildcards$AddCardRow(Props) {
  var rowData = Props.rowData;
  var index = Props.index;
  var reducer = Props.reducer;
  var species = rowData.species;
  var organisationId = rowData.organisationId;
  var name = rowData.name;
  var description = rowData.description;
  var commonName = rowData.commonName;
  var match = React.useState(function () {
        
      });
  var setEditing = match[1];
  var editing = match[0];
  var tmp;
  if (editing !== undefined) {
    switch (editing) {
      case /* Artist */0 :
          tmp = React.createElement(React.Fragment, undefined, "Adding artists isn't implemented yet");
          break;
      case /* CommonName */1 :
          tmp = React.createElement(AddWildcards$SetStringField, {
                lable: "Set the common animal name of the wildcard",
                currentValue: Belt_Option.getWithDefault(commonName, ""),
                setValue: (function (newValue) {
                    return Curry._1(reducer, {
                                TAG: /* SetCommonName */2,
                                _0: index,
                                _1: newValue
                              });
                  }),
                close: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  
                                }));
                  })
              });
          break;
      case /* Description */2 :
          tmp = React.createElement(AddWildcards$SetParagarphArrayField, {
                lable: "Edit description for the wildcard",
                currentValue: Belt_Option.mapWithDefault(description, [], GqlConverters.StringArray.parse),
                setValue: (function (newValue) {
                    return Curry._1(reducer, {
                                TAG: /* SetDescription */4,
                                _0: index,
                                _1: newValue
                              });
                  }),
                close: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  
                                }));
                  })
              });
          break;
      case /* Name */4 :
          tmp = React.createElement(AddWildcards$SetStringField, {
                lable: "Set the name of the wildcard",
                currentValue: Belt_Option.getWithDefault(name, ""),
                setValue: (function (newValue) {
                    return Curry._1(reducer, {
                                TAG: /* SetName */0,
                                _0: index,
                                _1: newValue
                              });
                  }),
                close: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  
                                }));
                  })
              });
          break;
      case /* Organization */5 :
          tmp = React.createElement(AddWildcards$SelectOrganisation, {
                lable: "Select the orginization for this wildcard",
                currentValue: Belt_Option.getWithDefault(organisationId, ""),
                setValue: (function (newValue) {
                    return Curry._1(reducer, {
                                TAG: /* SetOrganisation */3,
                                _0: index,
                                _1: newValue
                              });
                  }),
                close: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  
                                }));
                  })
              });
          break;
      case /* Image */3 :
      case /* RealWcPhotos */6 :
          tmp = null;
          break;
      case /* Species */7 :
          tmp = React.createElement(AddWildcards$SetStringField, {
                lable: "Set the species of the wildcard",
                currentValue: Belt_Option.getWithDefault(species, ""),
                setValue: (function (newValue) {
                    return Curry._1(reducer, {
                                TAG: /* SetSpecies */1,
                                _0: index,
                                _1: newValue
                              });
                  }),
                close: (function (param) {
                    return Curry._1(setEditing, (function (param) {
                                  
                                }));
                  })
              });
          break;
      
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement("tr", undefined, React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Name */4;
                                      }));
                        })
                    }, Belt_Option.getWithDefault(name, "")), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* CommonName */1;
                                      }));
                        })
                    }, Belt_Option.getWithDefault(commonName, "")), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Species */7;
                                      }));
                        })
                    }, Belt_Option.getWithDefault(species, "")), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Description */2;
                                      }));
                        })
                    }, React.createElement("div", undefined, React.createElement(Description.make, {
                              description: Belt_Option.getWithDefault(description, [])
                            }), React.createElement("h1", {
                              className: Curry._1(Css.style, {
                                    hd: Css.display("inline"),
                                    tl: /* [] */0
                                  }),
                              onClick: (function (param) {
                                  return Curry._1(setEditing, (function (param) {
                                                return /* Description */2;
                                              }));
                                })
                            }, " 🗒️✍ "))), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Image */3;
                                      }));
                        })
                    }, Belt_Option.getWithDefault(rowData.image, "")), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Artist */0;
                                      }));
                        })
                    }, Belt_Option.mapWithDefault(rowData.artistOfWildcard, "-", (function (param) {
                            var match = param.data;
                            return React.createElement(React.Fragment, undefined, Belt_Option.getWithDefault(match.name, ""), Belt_Option.mapWithDefault(match.website, null, (function (website) {
                                              return React.createElement("a", {
                                                          href: website
                                                        }, React.createElement("small", undefined, website));
                                            })), Belt_Option.mapWithDefault(match.eth_address, null, (function (ethAddress) {
                                              return React.createElement(React.Fragment, undefined, "EthAddr: ", React.createElement("a", {
                                                              href: "https://etherscan.io/address/" + ethAddress
                                                            }, React.createElement("small", undefined, ethAddress)));
                                            })));
                          }))), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* RealWcPhotos */6;
                                      }));
                        })
                    }, "todo"), React.createElement("td", {
                      onClick: (function (param) {
                          return Curry._1(setEditing, (function (param) {
                                        return /* Organization */5;
                                      }));
                        })
                    }, Belt_Option.mapWithDefault(organisationId, null, (function (orgId) {
                            return React.createElement(React.Fragment, undefined, React.createElement("a", {
                                            href: "https://wildcards.world/#org/" + orgId
                                          }, React.createElement("small", undefined, orgId)), " 🗒️✍ ");
                          })))), React.createElement("tr", undefined, tmp));
}

var AddCardRow = {
  make: AddWildcards$AddCardRow
};

function AddWildcards$1(Props) {
  var match = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var result = match[1];
  var mutate = match[0];
  var match$1 = React.useReducer((function (state, action) {
          if (typeof action === "number") {
            return {
                    data: Belt_Array.concat(state.data, [emptyNewCard]),
                    isCurrentRowValid: state.isCurrentRowValid
                  };
          }
          switch (action.TAG | 0) {
            case /* SetName */0 :
                var index = action._0;
                var init = Belt_Option.getWithDefault(Belt_Array.get(state.data, index), emptyNewCard);
                Belt_Array.set(state.data, index, {
                      artist: init.artist,
                      artistOfWildcard: init.artistOfWildcard,
                      commonName: init.commonName,
                      deprecated_id: init.deprecated_id,
                      description: init.description,
                      id: init.id,
                      image: init.image,
                      imageIpfsHash: init.imageIpfsHash,
                      ipfsMetadataHash: init.ipfsMetadataHash,
                      key: init.key,
                      name: action._1,
                      nftMetadata: init.nftMetadata,
                      organisationId: init.organisationId,
                      organization: init.organization,
                      real_wc_photos: init.real_wc_photos,
                      species: init.species
                    });
                return {
                        data: state.data,
                        isCurrentRowValid: state.isCurrentRowValid
                      };
            case /* SetSpecies */1 :
                var index$1 = action._0;
                var init$1 = Belt_Option.getWithDefault(Belt_Array.get(state.data, index$1), emptyNewCard);
                Belt_Array.set(state.data, index$1, {
                      artist: init$1.artist,
                      artistOfWildcard: init$1.artistOfWildcard,
                      commonName: init$1.commonName,
                      deprecated_id: init$1.deprecated_id,
                      description: init$1.description,
                      id: init$1.id,
                      image: init$1.image,
                      imageIpfsHash: init$1.imageIpfsHash,
                      ipfsMetadataHash: init$1.ipfsMetadataHash,
                      key: init$1.key,
                      name: init$1.name,
                      nftMetadata: init$1.nftMetadata,
                      organisationId: init$1.organisationId,
                      organization: init$1.organization,
                      real_wc_photos: init$1.real_wc_photos,
                      species: action._1
                    });
                return {
                        data: state.data,
                        isCurrentRowValid: state.isCurrentRowValid
                      };
            case /* SetCommonName */2 :
                var index$2 = action._0;
                var init$2 = Belt_Option.getWithDefault(Belt_Array.get(state.data, index$2), emptyNewCard);
                Belt_Array.set(state.data, index$2, {
                      artist: init$2.artist,
                      artistOfWildcard: init$2.artistOfWildcard,
                      commonName: action._1,
                      deprecated_id: init$2.deprecated_id,
                      description: init$2.description,
                      id: init$2.id,
                      image: init$2.image,
                      imageIpfsHash: init$2.imageIpfsHash,
                      ipfsMetadataHash: init$2.ipfsMetadataHash,
                      key: init$2.key,
                      name: init$2.name,
                      nftMetadata: init$2.nftMetadata,
                      organisationId: init$2.organisationId,
                      organization: init$2.organization,
                      real_wc_photos: init$2.real_wc_photos,
                      species: init$2.species
                    });
                return {
                        data: state.data,
                        isCurrentRowValid: state.isCurrentRowValid
                      };
            case /* SetOrganisation */3 :
                var index$3 = action._0;
                var init$3 = Belt_Option.getWithDefault(Belt_Array.get(state.data, index$3), emptyNewCard);
                Belt_Array.set(state.data, index$3, {
                      artist: init$3.artist,
                      artistOfWildcard: init$3.artistOfWildcard,
                      commonName: init$3.commonName,
                      deprecated_id: init$3.deprecated_id,
                      description: init$3.description,
                      id: init$3.id,
                      image: init$3.image,
                      imageIpfsHash: init$3.imageIpfsHash,
                      ipfsMetadataHash: init$3.ipfsMetadataHash,
                      key: init$3.key,
                      name: init$3.name,
                      nftMetadata: init$3.nftMetadata,
                      organisationId: action._1,
                      organization: init$3.organization,
                      real_wc_photos: init$3.real_wc_photos,
                      species: init$3.species
                    });
                return {
                        data: state.data,
                        isCurrentRowValid: state.isCurrentRowValid
                      };
            case /* SetDescription */4 :
                var description = action._1;
                var index$4 = action._0;
                var init$4 = Belt_Option.getWithDefault(Belt_Array.get(state.data, index$4), emptyNewCard);
                Belt_Array.set(state.data, index$4, {
                      artist: init$4.artist,
                      artistOfWildcard: init$4.artistOfWildcard,
                      commonName: init$4.commonName,
                      deprecated_id: init$4.deprecated_id,
                      description: description.length !== 0 ? Caml_option.some(GqlConverters.StringArray.serialize(description)) : undefined,
                      id: init$4.id,
                      image: init$4.image,
                      imageIpfsHash: init$4.imageIpfsHash,
                      ipfsMetadataHash: init$4.ipfsMetadataHash,
                      key: init$4.key,
                      name: init$4.name,
                      nftMetadata: init$4.nftMetadata,
                      organisationId: init$4.organisationId,
                      organization: init$4.organization,
                      real_wc_photos: init$4.real_wc_photos,
                      species: init$4.species
                    });
                return {
                        data: state.data,
                        isCurrentRowValid: state.isCurrentRowValid
                      };
            
          }
        }), {
        data: [emptyNewCard],
        isCurrentRowValid: false
      });
  var reducer = match$1[1];
  var state = match$1[0];
  var tmp;
  var exit = 0;
  if (result.called) {
    if (result.loading) {
      tmp = "Loading...";
    } else if (result.data !== undefined && result.error === undefined) {
      tmp = React.createElement(React.Fragment, undefined, React.createElement("p", undefined, "New wildcards have been added!"), React.createElement("br", undefined));
    } else {
      exit = 1;
    }
  } else {
    var wildcardsToInsert = state.data;
    tmp = React.createElement(React.Fragment, undefined, React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, " 🦒 ", " name"), React.createElement("th", undefined, " 🦇 ", " common name"), React.createElement("th", undefined, " 🦥 ", " species"), React.createElement("th", undefined, " 📋 ", " description"), React.createElement("th", undefined, " 🏷️ ", " image"), React.createElement("th", undefined, " 🧑‍🎨 ", " artist"), React.createElement("th", undefined, " 📸 ", " real images"), React.createElement("th", undefined, " 🏛️ ", " org"))), React.createElement("tbody", undefined, Belt_Array.mapWithIndex(state.data, (function (index, wildcardData) {
                        return React.createElement(AddWildcards$AddCardRow, {
                                    rowData: wildcardData,
                                    index: index,
                                    reducer: reducer
                                  });
                      })))), React.createElement("button", {
              onClick: (function (param) {
                  return Curry._1(reducer, /* AddRow */0);
                })
            }, "+ Add row"), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("br", undefined), React.createElement("br", undefined), wildcardsToInsert.length !== 0 ? React.createElement("button", {
                onClick: (function (param) {
                    Curry._8(mutate, undefined, undefined, undefined, undefined, undefined, [Curry._3(Queries.AllWildcardsData.refetchQueryDescription, undefined, undefined, undefined)], undefined, {
                          wildcardsToInsert: wildcardsToInsert
                        });
                    
                  })
              }, "Save Wildcards") : null);
  }
  if (exit === 1) {
    var error = result.error;
    tmp = React.createElement(React.Fragment, undefined, "Error loading data", error !== undefined ? ": " + error.message : null);
  }
  return React.createElement("div", undefined, React.createElement("h3", undefined, "Add new wildcards: (this feature doesn't work yet)"), React.createElement(AuthComponent.make, {
                  children: tmp
                }));
}

var make = AddWildcards$1;

export {
  AddWildcards ,
  AllOrgIds ,
  emptyNewCard ,
  stringToParagraphs ,
  SetParagarphArrayField ,
  SetStringField ,
  SelectOrganisation ,
  AddCardRow ,
  make ,
  
}
/* query Not a pure module */

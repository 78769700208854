// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Decco from "decco/src/Decco.bs.js";
import * as Belt_Result from "bs-platform/lib/es6/belt_Result.js";

function stringArray_encode(v) {
  return Decco.arrayToJson(Decco.stringToJson, v);
}

function stringArray_decode(v) {
  return Decco.arrayFromJson(Decco.stringFromJson, v);
}

function parse(json) {
  return Belt_Result.getWithDefault(Decco.arrayFromJson(Decco.stringFromJson, json), []);
}

function serialize(array) {
  return Decco.arrayToJson(Decco.stringToJson, array);
}

var StringArray = {
  parse: parse,
  serialize: serialize
};

export {
  stringArray_encode ,
  stringArray_decode ,
  StringArray ,
  
}
/* No side effect */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Youtube from "./components/Youtube.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import ReactModal from "react-modal";
import * as GqlConverters from "./GqlConverters.bs.js";
import * as ApolloClient__React_Hooks_UseQuery from "reason-apollo-client/src/@apollo/client/react/hooks/ApolloClient__React_Hooks_UseQuery.bs.js";

((require("./styles/css/create-stream.css")));

((require("./styles/css/global.css")));

((require("./styles/css/defaults.css")));

var $pipe$pipe$pipe$pipe = Belt_Option.getWithDefault;

var Raw = {};

var query = (require("@apollo/client").gql`
  query AllOrgs  {
    organisations  {
      __typename
      announcement_blog
      country
      cover_image
      description
      id
      logo
      logo_badge
      name
      onboarding_status
      website
      youtube_vid
    }
  }
`);

function parse(value) {
  var value$1 = value.organisations;
  return {
          organisations: value$1.map(function (value) {
                var value$1 = value.announcement_blog;
                var value$2 = value.country;
                var value$3 = value.cover_image;
                var value$4 = value.logo_badge;
                var value$5 = value.onboarding_status;
                var tmp;
                switch (value$5) {
                  case "contacted" :
                      tmp = "contacted";
                      break;
                  case "declined" :
                      tmp = "declined";
                      break;
                  case "interested" :
                      tmp = "interested";
                      break;
                  case "listed" :
                      tmp = "listed";
                      break;
                  case "live" :
                      tmp = "live";
                      break;
                  case "rejected" :
                      tmp = "rejected";
                      break;
                  case "signed" :
                      tmp = "signed";
                      break;
                  case "unprocessed" :
                      tmp = "unprocessed";
                      break;
                  default:
                    tmp = {
                      NAME: "FutureAddedValue",
                      VAL: value$5
                    };
                }
                var value$6 = value.youtube_vid;
                return {
                        __typename: value.__typename,
                        announcement_blog: !(value$1 == null) ? value$1 : undefined,
                        country: !(value$2 == null) ? value$2 : undefined,
                        cover_image: !(value$3 == null) ? value$3 : undefined,
                        description: GqlConverters.StringArray.parse(value.description),
                        id: value.id,
                        logo: value.logo,
                        logo_badge: !(value$4 == null) ? value$4 : undefined,
                        name: value.name,
                        onboarding_status: tmp,
                        website: value.website,
                        youtube_vid: !(value$6 == null) ? value$6 : undefined
                      };
              })
        };
}

function serialize(value) {
  var value$1 = value.organisations;
  var organisations = value$1.map(function (value) {
        var value$1 = value.youtube_vid;
        var youtube_vid = value$1 !== undefined ? value$1 : null;
        var value$2 = value.website;
        var value$3 = value.onboarding_status;
        var onboarding_status = typeof value$3 === "string" ? (
            value$3 === "declined" ? "declined" : (
                value$3 === "unprocessed" ? "unprocessed" : (
                    value$3 === "interested" ? "interested" : (
                        value$3 === "listed" ? "listed" : (
                            value$3 === "rejected" ? "rejected" : (
                                value$3 === "signed" ? "signed" : (
                                    value$3 === "contacted" ? "contacted" : "live"
                                  )
                              )
                          )
                      )
                  )
              )
          ) : value$3.VAL;
        var value$4 = value.name;
        var value$5 = value.logo_badge;
        var logo_badge = value$5 !== undefined ? value$5 : null;
        var value$6 = value.logo;
        var value$7 = value.id;
        var value$8 = value.description;
        var value$9 = GqlConverters.StringArray.serialize(value$8);
        var value$10 = value.cover_image;
        var cover_image = value$10 !== undefined ? value$10 : null;
        var value$11 = value.country;
        var country = value$11 !== undefined ? value$11 : null;
        var value$12 = value.announcement_blog;
        var announcement_blog = value$12 !== undefined ? value$12 : null;
        var value$13 = value.__typename;
        return {
                __typename: value$13,
                announcement_blog: announcement_blog,
                country: country,
                cover_image: cover_image,
                description: value$9,
                id: value$7,
                logo: value$6,
                logo_badge: logo_badge,
                name: value$4,
                onboarding_status: onboarding_status,
                website: value$2,
                youtube_vid: youtube_vid
              };
      });
  return {
          organisations: organisations
        };
}

function serializeVariables(param) {
  
}

function makeVariables(param) {
  
}

function makeDefaultVariables(param) {
  
}

var AllOrganisations_inner = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables
};

var include = ApolloClient__React_Hooks_UseQuery.Extend({
      query: query,
      Raw: Raw,
      parse: parse,
      serialize: serialize,
      serializeVariables: serializeVariables
    });

var use = include.use;

var AllOrganisations_refetchQueryDescription = include.refetchQueryDescription;

var AllOrganisations_useLazy = include.useLazy;

var AllOrganisations_useLazyWithVariables = include.useLazyWithVariables;

var AllOrganisations = {
  AllOrganisations_inner: AllOrganisations_inner,
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables,
  makeDefaultVariables: makeDefaultVariables,
  refetchQueryDescription: AllOrganisations_refetchQueryDescription,
  use: use,
  useLazy: AllOrganisations_useLazy,
  useLazyWithVariables: AllOrganisations_useLazyWithVariables
};

var customStyles = {
  content: {
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%"
  }
};

var ReactModal$1 = {};

function OrgOverview$CDNImageLink(Props) {
  var cdnPath = Props.cdnPath;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement("div", undefined, React.createElement(ReactModal, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  style: customStyles,
                  children: React.createElement("img", {
                        src: "https://dd2wadt5nc0o7.cloudfront.net" + cdnPath
                      })
                }), React.createElement("div", {
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, cdnPath));
}

var CDNImageLink = {
  make: OrgOverview$CDNImageLink
};

function OrgOverview$Description(Props) {
  var description = Props.description;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  var x = description.length;
  return React.createElement("div", undefined, React.createElement(ReactModal, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  style: customStyles,
                  children: Belt_Array.map(description, (function (prim) {
                          return prim;
                        }))
                }), React.createElement("div", {
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, x !== 0 ? String(x) + " paragraph description" : "NO DESCRIPTION"));
}

var Description = {
  make: OrgOverview$Description
};

function OrgOverview$YoutubeModal(Props) {
  var optVideoCode = Props.optVideoCode;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement("div", undefined, optVideoCode !== undefined ? React.createElement(React.Fragment, undefined, React.createElement(ReactModal, {
                        isOpen: match[0],
                        contentLabel: "hello world",
                        onAfterOpen: (function (param) {
                            
                          }),
                        onRequestClose: (function (param) {
                            return Curry._1(setShowModal, (function (param) {
                                          return false;
                                        }));
                          }),
                        style: customStyles,
                        children: React.createElement(Youtube.make, {
                              videoCode: optVideoCode
                            })
                      }), React.createElement("div", {
                        onClick: (function (param) {
                            return Curry._1(setShowModal, (function (param) {
                                          return true;
                                        }));
                          })
                      }, optVideoCode)) : "NO VIDEO");
}

var YoutubeModal = {
  make: OrgOverview$YoutubeModal
};

function OrgOverview$Onboarding(Props) {
  var onboarding_status = Props.onboarding_status;
  return React.createElement("div", undefined, "", onboarding_status === "live" ? "Live" : (
                onboarding_status === "declined" ? "Declined" : (
                    onboarding_status === "unprocessed" ? "Unprocessed" : (
                        onboarding_status === "interested" ? "Interested" : (
                            onboarding_status === "listed" ? "Listed" : (
                                onboarding_status === "rejected" ? "Rejected" : (
                                    onboarding_status === "signed" ? "Signed " : (
                                        onboarding_status === "contacted" ? "Contacted" : "Unknown - please update ui"
                                      )
                                  )
                              )
                          )
                      )
                  )
              ));
}

var Onboarding = {
  make: OrgOverview$Onboarding
};

function OrgOverview(Props) {
  var allOrgsQuery = Curry.app(use, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined
      ]);
  var onClick = function (id, _event) {
    console.log("clicked the id: " + id);
    
  };
  var match = allOrgsQuery.data;
  var _error = allOrgsQuery.error;
  if (allOrgsQuery.loading) {
    return "Loading...";
  } else if (_error !== undefined) {
    console.log(_error);
    return "Error loading data";
  } else if (match !== undefined) {
    return React.createElement("table", undefined, React.createElement("thead", undefined, React.createElement("tr", undefined, React.createElement("th", undefined, " 📁 ", " name"), React.createElement("th", undefined, " 📥 ", " id"), React.createElement("th", undefined, " 🌍 ", " contry code"), React.createElement("th", undefined, " 🕔 ", " logo image"), React.createElement("th", undefined, " ⏳ ", " logo badge"), React.createElement("th", undefined, " 🧮 ", " cover image"), React.createElement("th", undefined, " 📋 ", " description"), React.createElement("th", undefined, " 🏷️ ", " announcement blog"), React.createElement("th", undefined, " 🌐 ", " website"), React.createElement("th", undefined, " 🎞 ", " youtube vid"), React.createElement("th", undefined, " 🏗️ ", " status"))), React.createElement("tbody", undefined, Belt_Array.map(match.organisations, (function (param) {
                          var website = param.website;
                          var onboarding_status = param.onboarding_status;
                          var id = param.id;
                          console.log(onboarding_status);
                          return React.createElement("tr", {
                                      key: id
                                    }, React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, param.name), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, id), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, Belt_Option.getWithDefault(param.country, "")), React.createElement("td", undefined, React.createElement(OrgOverview$CDNImageLink, {
                                              cdnPath: param.logo
                                            })), React.createElement("td", undefined, Belt_Option.mapWithDefault(param.logo_badge, "", (function (logoBadge) {
                                                return React.createElement(OrgOverview$CDNImageLink, {
                                                            cdnPath: logoBadge
                                                          });
                                              }))), React.createElement("td", undefined, Belt_Option.mapWithDefault(param.cover_image, "", (function (coverImage) {
                                                return React.createElement(OrgOverview$CDNImageLink, {
                                                            cdnPath: coverImage
                                                          });
                                              }))), React.createElement("td", undefined, React.createElement(OrgOverview$Description, {
                                              description: param.description
                                            })), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, Belt_Option.getWithDefault(param.announcement_blog, "")), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, React.createElement("a", {
                                              href: website,
                                              target: "_blank"
                                            }, React.createElement("small", undefined, website))), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, React.createElement(OrgOverview$YoutubeModal, {
                                              optVideoCode: param.youtube_vid
                                            })), React.createElement("td", {
                                          onClick: (function (param) {
                                              return onClick(id, param);
                                            })
                                        }, React.createElement(OrgOverview$Onboarding, {
                                              onboarding_status: onboarding_status
                                            })));
                        }))));
  } else {
    return null;
  }
}

var make = OrgOverview;

export {
  $pipe$pipe$pipe$pipe ,
  AllOrganisations ,
  customStyles ,
  ReactModal$1 as ReactModal,
  CDNImageLink ,
  Description ,
  YoutubeModal ,
  Onboarding ,
  make ,
  
}
/*  Not a pure module */

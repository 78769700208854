// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as ReactModal from "./ReactModal.bs.js";
import ReactModal$1 from "react-modal";

function Description(Props) {
  var description = Props.description;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  var x = description.length;
  return React.createElement(React.Fragment, undefined, React.createElement(ReactModal$1, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  style: ReactModal.customStyles,
                  children: description.length !== 0 ? Belt_Array.map(description, (function (paragraph) {
                            return React.createElement("p", undefined, paragraph);
                          })) : "NO DESCRIPTION SET"
                }), React.createElement("span", {
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, x !== 0 ? String(x) + " paragraph description" : "NO DESCRIPTION"));
}

var make = Description;

export {
  make ,
  
}
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App from "./App.bs.js";
import * as React from "react";
import * as ReactDOMRe from "reason-react/src/legacy/ReactDOMRe.bs.js";

ReactDOMRe.renderToElementWithId(React.createElement(App.make, {}), "root");

export {
  
}
/*  Not a pure module */

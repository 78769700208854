// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "bs-platform/lib/es6/curry.js";
import * as React from "react";
import * as ReactModal from "./ReactModal.bs.js";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";
import ReactModal$1 from "react-modal";

function CDNImageLink(Props) {
  var cdnPath = Props.cdnPath;
  var updateAnimation = Props.updateAnimation;
  var match = React.useState(function () {
        return false;
      });
  var setShowModal = match[1];
  return React.createElement("div", undefined, React.createElement(ReactModal$1, {
                  isOpen: match[0],
                  contentLabel: "hello world",
                  onAfterOpen: (function (param) {
                      
                    }),
                  onRequestClose: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return false;
                                  }));
                    }),
                  style: ReactModal.customStyles,
                  children: null
                }, updateAnimation, Belt_Option.mapWithDefault(cdnPath, React.createElement("p", undefined, "you need to upload an image still"), (function (imagePath) {
                        return React.createElement("img", {
                                    src: "https://dd2wadt5nc0o7.cloudfront.net" + imagePath
                                  });
                      }))), React.createElement("div", {
                  onClick: (function (param) {
                      return Curry._1(setShowModal, (function (param) {
                                    return true;
                                  }));
                    })
                }, Belt_Option.getWithDefault(cdnPath, "NO IMAGE")));
}

var make = CDNImageLink;

export {
  make ,
  
}
/* react Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Css from "bs-css-emotion/src/Css.bs.js";
import * as Curry from "bs-platform/lib/es6/curry.js";
import * as Css_Legacy_Core from "bs-css/src/Css_Legacy_Core.bs.js";

Curry._2(Css.$$global, "a", {
      hd: Css.color(Css.hex("303030")),
      tl: {
        hd: Css.cursor("pointer"),
        tl: {
          hd: Css.textDecoration(Css.none),
          tl: /* [] */0
        }
      }
    });

Curry._2(Css.$$global, "a:hover", {
      hd: Css.textDecoration(Css.underline),
      tl: /* [] */0
    });

var buttonStyles = Curry._1(Css.style, {
      hd: Css.important(Css.color(Css.white)),
      tl: {
        hd: Css.textTransform("uppercase"),
        tl: {
          hd: Css.textDecoration("none"),
          tl: {
            hd: Css.background({
                  NAME: "rgb",
                  VAL: [
                    13,
                    42,
                    94
                  ]
                }),
            tl: {
              hd: Css.padding(Css.px(20)),
              tl: {
                hd: Css.borderRadius(Css.px(5)),
                tl: {
                  hd: Css.display("inlineBlock"),
                  tl: {
                    hd: Css.border(Css.px(0), "none", Css.white),
                    tl: {
                      hd: Css.transition(400, 0, Css.ease, "all"),
                      tl: {
                        hd: Css.hover({
                              hd: Css.background({
                                    NAME: "hex",
                                    VAL: "434343"
                                  }),
                              tl: {
                                hd: Css.letterSpacing(Css.px(1)),
                                tl: {
                                  hd: Css.boxShadow(Css_Legacy_Core.Shadow.box(Css.px(0), Css.px(5), Css.px(5), undefined, undefined, Css.rgba(0, 0, 0, {
                                                NAME: "num",
                                                VAL: 0.57
                                              }))),
                                  tl: /* [] */0
                                }
                              }
                            }),
                        tl: /* [] */0
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

export {
  buttonStyles ,
  
}
/*  Not a pure module */

// Generated by ReScript, PLEASE EDIT WITH CARE

import YoutubeJs from "./Youtube.js";

var make = YoutubeJs;

export {
  make ,
  
}
/* make Not a pure module */
